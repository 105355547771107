import { DeviceCommandInstruction } from '../types';

const SUPPORTED_ACTIONS_HONGJI = [
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.lock,
  DeviceCommandInstruction.unlock,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.batteryLock,
  DeviceCommandInstruction.batteryUnlock,
  DeviceCommandInstruction.rebootIot
];

export const HONGHO0000 = {
  id: 'HONGHO0000',
  name: 'Hongji BH-06',
  supportedActions: SUPPORTED_ACTIONS_HONGJI
};

export const HONGJI_DEVICES = [HONGHO0000];
