import {
  Checkbox as BaseWebCheckbox,
  CheckboxProps as BaseWebCheckboxProps,
  STYLE_TYPE,
} from 'baseui/checkbox';
import { expandBorderStyles } from 'baseui/styles';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';

export { STYLE_TYPE };

export const Checkbox = ({ children, ...others }: BaseWebCheckboxProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <BaseWebCheckbox
      {...others}
      overrides={{
        Checkmark: {
          style: ({ $checked, $isIndeterminate }): StyleObject => ({
            backgroundColor:
              $checked || $isIndeterminate ? theme.colors.primary100 : theme.colors.bgWhite,
            height: theme.sizes.scale400,
            width: theme.sizes.scale400,
            ...expandBorderStyles({
              borderColor: $checked || $isIndeterminate ? 'transparent' : theme.colors.bg25,
              borderStyle: 'solid',
              borderWidth: '1.5px',
            }),
          }),
        },
        Label: {
          style: (): StyleObject => ({
            fontSize: theme.sizing.scale500,
          }),
        },
        Root: {
          style: ({ $checkmarkType }): StyleObject => ({
            alignItems: 'center',
            display: 'flex',
            marginLeft:
              $checkmarkType === STYLE_TYPE.toggle_round ? `-${theme.sizes.scale300}` : 'inherit',
          }),
        },
        Toggle: {
          style: ({ $checked }): StyleObject => ({
            backgroundColor: theme.colors.bgWhite,
            height: theme.sizes.scale400,
            marginLeft: $checked ? theme.sizes.scale300 : theme.sizes.scale100,
            width: theme.sizes.scale400,
          }),
        },
        ToggleTrack: {
          style: ({ $checked }): StyleObject => ({
            backgroundColor: $checked ? theme.colors.success100 : theme.colors.bg25,
            borderBottomLeftRadius: theme.sizes.scale300,
            borderBottomRightRadius: theme.sizes.scale300,
            borderTopLeftRadius: theme.sizes.scale300,
            borderTopRightRadius: theme.sizes.scale300,
            height: theme.sizes.scale500,
            width: theme.sizes.scale650,
          }),
        },
      }}
    >
      {children}
    </BaseWebCheckbox>
  );
};
