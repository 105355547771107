import { KIND, Tag } from 'baseui/tag';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { FilterTypes } from '../../stores/deviceStore';
import { themedStyled } from '../../theme/theme';

const TagContainer = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    marginLeft: $theme.sizes.scale300,
    marginRight: $theme.sizes.scale300,
  }),
);

export type NameValueType = {
  name: string;
  value: string;
};

export interface ActiveFilterValueMap {
  deviceIds: NameValueType[];
  deviceConfigurations: NameValueType[];
  deviceTypes: NameValueType[];
  firmewareVersions: NameValueType[];
}

export type OnRemoveFilterAction = (filterType: FilterTypes, filterValues: string[]) => void;

interface DeviceListBarFilterTagsProps {
  activeFilterValueMap: ActiveFilterValueMap;
  onActionClick: OnRemoveFilterAction;
}

interface TagListProps {
  values: NameValueType[];
  kind: KIND[keyof KIND];
  filterType: FilterTypes;
  onActionClick: OnRemoveFilterAction;
}

const FilterTagList: React.FC<TagListProps> = ({
  filterType,
  kind,
  onActionClick,
  values,
}: TagListProps) => {
  return (
    <>
      {values.map((value) => (
        <Tag
          key={value.name}
          kind={kind}
          onActionClick={() => onActionClick(filterType, [value.name])}
        >
          {value.value}
        </Tag>
      ))}
    </>
  );
};

type DeviceIdFilterTagListProps = Omit<TagListProps, 'kind' | 'filterType'>;

const DeviceIdFilterTagList: React.FC<DeviceIdFilterTagListProps> = ({
  onActionClick,
  values,
}: DeviceIdFilterTagListProps) => {
  if (values.length > 5) {
    const firstDeviceId = values[0].name;
    const deviceNames = values.map((deviceId) => deviceId.name);

    return (
      <Tag
        key={firstDeviceId}
        kind="neutral"
        onActionClick={() => onActionClick(FilterTypes.DeviceIds, deviceNames)}
      >
        {`DeviceIds: ${firstDeviceId}...`}
      </Tag>
    );
  }

  return (
    <FilterTagList
      values={values}
      filterType={FilterTypes.DeviceIds}
      kind="neutral"
      onActionClick={onActionClick}
    />
  );
};

export const DeviceListBarFilterTags = ({
  activeFilterValueMap,
  onActionClick,
}: DeviceListBarFilterTagsProps): JSX.Element => {
  const { deviceConfigurations, deviceIds, deviceTypes, firmewareVersions } = activeFilterValueMap;

  return (
    <TagContainer>
      <DeviceIdFilterTagList values={deviceIds} onActionClick={onActionClick} />
      <FilterTagList
        values={deviceConfigurations}
        filterType={FilterTypes.ConfigurationRefIds}
        kind="positive"
        onActionClick={onActionClick}
      />
      <FilterTagList
        values={deviceTypes}
        filterType={FilterTypes.DeviceTypeIds}
        kind="accent"
        onActionClick={onActionClick}
      />
      <FilterTagList
        values={firmewareVersions}
        filterType={FilterTypes.FirmwareVersions}
        kind="negative"
        onActionClick={onActionClick}
      />
    </TagContainer>
  );
};
