import { DatePicker } from 'baseui/datepicker';
import { FormControl } from 'baseui/form-control';
import React from 'react';

import { DEVICE_LOGS_MODAL_DATE_PICKER } from '../../e2e/testIds';
import { themedUseStyletron } from '../../theme/theme';

export const DeviceLogModalInput = ({
  value,
  onChange,
}: {
  value: Date[];
  onChange: (dates: Date[]) => void;
}): JSX.Element => {
  const [css] = themedUseStyletron();

  return (
    <div className={css({ alignItems: 'center', display: 'flex', width: '100%' })}>
      <div
        className={css({
          flexGrow: 1,
        })}
      >
        <FormControl label="Date (UTC time format)">
          <DatePicker
            value={value}
            onChange={({ date }) => {
              // prevents crash, if starttime is higher than endtime, by sorting the dates
              const sortedDates = (date as Array<Date>).sort(
                (a: Date, b: Date) => a.getTime() - b.getTime(),
              );
              onChange(sortedDates);
            }}
            range
            monthsShown={2}
            formatString="dd-MM-yyyy HH:mm"
            placeholder="Start Date"
            timeSelectStart
            timeSelectEnd
            overrides={{
              Input: {
                props: {
                  overrides: {
                    Input: {
                      props: {
                        'data-test-id': DEVICE_LOGS_MODAL_DATE_PICKER,
                      },
                    },
                  },
                },
              },
              TimeSelect: { props: { format: 24 } },
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};
