import { action, observable } from 'mobx';

import { AuthClient } from '../client/interfaces';
import { UserDocument } from '../models/userDocument';
import { TokenHandler } from '../utils/tokenHandler';

export class UserStore {
  @observable userIdToken: string | null = null;

  @observable currentUser: UserDocument | null = null;

  @observable loginError: string | null = null;

  constructor(readonly authClient: AuthClient) {}

  @action
  public async loginUser(email: string, password: string): Promise<void> {
    this.loginError = null;

    try {
      const { success, token } = await this.authClient.authenticate(email, password);
      if (success && token) {
        const user = await this.authClient.getCurrentUser();
        if (user) {
          this.loginError = null;
          this.currentUser = new UserDocument(user);
          this.userIdToken = this.getIdToken();
        }
      }
    } catch (err) {
      if (err.code === 'auth/too-many-requests') {
        this.loginError = 'Too many unsuccessful login attempts. Please try again later.';
      } else {
        this.loginError = 'The email address or password you entered is invalid.';
      }
    }
  }

  @action
  public async fetchCurrentUser(): Promise<void> {
    const token = TokenHandler.getToken();
    if (token !== '' && this.currentUser == null) {
      const user = await this.authClient.getCurrentUser();
      if (user) {
        this.currentUser = new UserDocument(user);
        this.userIdToken = this.getIdToken();
      } else {
        this.currentUser = null;
        TokenHandler.removeToken();
      }
    }
  }

  @action
  public removeCurrentUser(): void {
    this.currentUser = null;
    TokenHandler.removeToken();
  }

  @action
  public resetLoginError(): void {
    this.loginError = null;
  }

  private getIdToken(): string | null {
    const token = TokenHandler.getToken();
    if (token) {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).claims.idToken;
    }
    return null;
  }
}
