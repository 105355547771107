import { DeviceCommandInstruction } from '../types';

const SUPPORTED_ACTIONS_SEGWAY_OMNI = [
  DeviceCommandInstruction.alarm,
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.volumeOn,
  DeviceCommandInstruction.volumeOff,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.currentPosition,
  DeviceCommandInstruction.info,
  DeviceCommandInstruction.firmware,
  DeviceCommandInstruction.lightsOn,
  DeviceCommandInstruction.lightsOff,
  DeviceCommandInstruction.throttleOn,
  DeviceCommandInstruction.throttleOff,
  DeviceCommandInstruction.rebootIot,
  DeviceCommandInstruction.turnOffIot,
  DeviceCommandInstruction.setMaxSpeed,
  DeviceCommandInstruction.firmwareUpdate,
  DeviceCommandInstruction.cableUnlock,
  DeviceCommandInstruction.batteryUnlock
];

export const SEGWOM0001 = {
  id: 'SEGWOM0001',
  name: 'Segway Ninebot V1.2.8',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0002 = {
  id: 'SEGWOM0002',
  name: 'Segway ES4',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0003 = {
  id: 'SEGWOM0003',
  name: 'Segway Max',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0004 = {
  id: 'SEGWOM0004',
  name: 'Segway Max Pro',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0005 = {
  id: 'SEGWOM0005',
  name: 'Segway Max Plus X',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0006 = {
  id: 'SEGWOM0006',
  name: 'Segway Max Plus',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWOM0007 = {
  id: 'SEGWOM0007',
  name: 'Segway eBike',
  supportedActions: SUPPORTED_ACTIONS_SEGWAY_OMNI
};

export const SEGWAY_DEVICES = [
  SEGWOM0001,
  SEGWOM0002,
  SEGWOM0003,
  SEGWOM0004,
  SEGWOM0005,
  SEGWOM0006,
  SEGWOM0007
];
