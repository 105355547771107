import {
  Tabs as BaseWebTabs,
  TabsProps as BaseWebTabsProps,
  Tab as BaseWebTab,
  TabProps as BaseWebTabProps,
} from 'baseui/tabs';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';

interface TabsProps extends BaseWebTabsProps {
  style?: StyleObject;
}

export const Tabs = ({ children, style, ...others }: TabsProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <BaseWebTabs
      {...others}
      overrides={{
        TabContent: {
          style: (): StyleObject => ({
            paddingLeft: theme.sizes.scale0,
            paddingRight: theme.sizes.scale0,
          }),
        },
        TabBar: {
          style: (): StyleObject => ({
            backgroundColor: '#F0F1F2',
            paddingLeft: theme.sizes.scale0,
            paddingRight: theme.sizes.scale0,

            // must be last to override the defaults!
            ...style,
          }),
        },
      }}
    >
      {children}
    </BaseWebTabs>
  );
};

export const Tab = ({ children, ...others }: BaseWebTabProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <BaseWebTab
      {...others}
      overrides={{
        Tab: {
          style: ({ $active }): StyleObject => ({
            ...theme.typography.contentBodyRegular,
            color: $active ? theme.colors.text100 : theme.colors.text50,
            borderBottom: `2px solid ${$active ? theme.colors.primary100 : 'transparent'}`,
            paddingTop: theme.sizes.scale0,
            paddingRight: theme.sizes.scale0,
            paddingBottom: theme.sizes.scale100,
            paddingLeft: theme.sizes.scale0,
            marginLeft: theme.sizes.scale200,
            marginRight: theme.sizes.scale200,

            ':first-child': {
              marginLeft: theme.sizes.scale0,
            },
          }),
        },
      }}
    >
      {children}
    </BaseWebTab>
  );
};
