import { Input } from 'baseui/input';
import { Field, FieldMetaProps, FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';
import React from 'react';

import { Checkbox, STYLE_TYPE } from '../checkbox/Checkbox';
import { Textarea } from '../textarea/Textarea';

import { CustomNumberInput } from './CustomNumberInput';
import { CustomRadioGroup } from './CustomRadioGroup';
import { TranslatedFormControl } from './TranslatedFormControl';
import {
  FieldGroupOption,
  FieldType,
  FormField,
  FormFieldNumberOptions,
  FormFieldSelectOption
} from './formFieldTypes';

interface FieldOptions<FormValues> extends FormikProps<FormValues> {
  loading: boolean;
}

type BasicCreateFieldParameter = {
  name: string;
  type: FieldType;
  placeholder?: string;
  options?: FieldGroupOption | FormFieldNumberOptions | FormFieldSelectOption[];
  fields?: FormField[];
  validation?: (value: unknown) => string | undefined;
};
type CreateFieldParameter =
  | (BasicCreateFieldParameter & { label: string })
  | (BasicCreateFieldParameter & { translationKey: string });

type CreateFieldFn = (formField: CreateFieldParameter) => JSX.Element | null;

type Validatator = (value: unknown) => string | undefined;
type ChangeHandler = (e: React.SyntheticEvent) => void;

function renderText<FormValues>({
  label,
  change,
  loading,
  name,
  translationKey,
  validation,
  values
}: {
  change: ChangeHandler;
  label?: string;
  loading: boolean;
  name: string;
  translationKey?: string;
  validation?: Validatator;
  values: FormValues;
}) {
  return (
    <Field name={name} key={name} validate={validation}>
      {({ meta }: { meta: FieldMetaProps<string> }) => {
        return (
          <TranslatedFormControl
            key={name}
            label={label}
            translationKey={translationKey}
            error={meta.error}
          >
            <Input
              name={name}
              disabled={loading}
              value={get(values, name)}
              onChange={change}
              error={meta.error ? true : undefined}
            />
          </TranslatedFormControl>
        );
      }}
    </Field>
  );
}

export function renderFields<FormValues>(
  fields: FormField[],
  {
    values,
    handleChange,
    setFieldTouched,
    loading,
    setFieldValue,
    validateField
  }: FieldOptions<FormValues>
): JSX.Element {
  let createField: CreateFieldFn;

  const createFields = (formFields: FormField[]): (JSX.Element | null)[] => {
    return formFields.map(createField);
  };

  createField = ({
    name,
    label,
    placeholder,
    translationKey,
    type,
    disabled,
    options,
    unit,
    fields: childFieldsProps,
    validation
  }: {
    name: string;
    label?: string;
    placeholder?: string;
    translationKey?: string;
    type: FieldType;
    disabled?: (values: FormikValues) => boolean;
    options?: FieldGroupOption | FormFieldNumberOptions | FormFieldSelectOption[];
    unit?: string;
    fields?: FormField[];
    validation?: Validatator;
  }): JSX.Element | null => {
    let childFields = childFieldsProps;

    const valueChange = (value: unknown): void => {
      setFieldValue(name, value);
      setFieldTouched(name, true, false);
      validateField(name);
    };

    const change = (e: React.SyntheticEvent): void => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
      validateField(name);
    };

    const changeTextarea = (e: React.FormEvent<HTMLTextAreaElement>): void => {
      e.persist();
      handleChange(e.currentTarget.value);
      setFieldTouched(name, true, false);
      setFieldValue(name, e.currentTarget.value);
      validateField(name);
    };

    switch (type) {
      case 'group':
        if (!childFields) {
          return null;
        }

        if ((options as FieldGroupOption)?.deactivatable) {
          const switchName = `${name}.enabled`;
          const switchField = {
            label: '',
            name: switchName,
            type: 'switch'
          } as FormField;
          childFields = get(values, switchName) ? [switchField, ...childFields] : [switchField];
        }

        return (
          <div key={name}>
            <h2>{label}</h2>
            {createFields(childFields)}
          </div>
        );

      case 'text':
        return renderText<FormValues>({
          change,
          label,
          loading,
          name,
          translationKey,
          validation,
          values
        });

      case 'textarea':
        return (
          <Field name={name} key={name} validate={validation}>
            {({ meta }: { meta: FieldMetaProps<string> }) => {
              return (
                <TranslatedFormControl
                  key={name}
                  label={label}
                  translationKey={translationKey}
                  error={meta.touched ? meta.error : undefined}
                >
                  <Textarea
                    name={name}
                    value={get(values, name)}
                    onChange={changeTextarea}
                    placeholder={placeholder}
                  />
                </TranslatedFormControl>
              );
            }}
          </Field>
        );

      case 'disabled':
        return (
          <TranslatedFormControl key={name} label={label} translationKey={translationKey}>
            <Input name={name} disabled value={get(values, name)} />
          </TranslatedFormControl>
        );

      case 'switch':
        return (
          <TranslatedFormControl key={name} label={label} translationKey={translationKey}>
            <Checkbox
              name={name}
              checked={!!get(values, name)}
              checkmarkType={STYLE_TYPE.toggle_round}
              onChange={change}
            />
          </TranslatedFormControl>
        );

      case 'number':
        return (
          <TranslatedFormControl key={name} label={label} translationKey={translationKey}>
            <CustomNumberInput
              key={name}
              inputProps={options as FormFieldNumberOptions}
              name={name}
              value={get(values, name)}
              onChange={change}
              endEnhancerText={unit}
              disabled={(disabled && disabled(values as FormikValues)) || undefined}
            />
          </TranslatedFormControl>
        );

      case 'select':
        return (
          <TranslatedFormControl key={name} label={label} translationKey={translationKey}>
            <CustomRadioGroup
              key={name}
              name={name}
              value={get(values, name)}
              onChange={valueChange}
              options={options as FormFieldSelectOption[]}
            />
          </TranslatedFormControl>
        );

      default:
        return null;
    }
  };

  return <>{createFields(fields)}</>;
}
