import { observable, action } from 'mobx';

export class UiStore {
  @observable isSidebarOpen = false;

  @action
  updateIsSidebarOpenValue = (isSidebarOpen: boolean): void => {
    this.isSidebarOpen = isSidebarOpen;
  };
}
