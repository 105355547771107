export type AccessLevel = 'read' | 'write' | 'admin';

export interface TenantAccessInfo {
  id: string;
  name: string;
  accessLevel: AccessLevel;
  apiUrl: string;
  apiKey: string;
}

export interface User {
  admin: boolean;
  email: string;
  id: string;
  tenants: TenantAccessInfo[];
}

export class UserDocument {
  private selectedTenantId: string | undefined = undefined;

  readonly id: string;

  readonly email: string;

  private readonly tenants: TenantAccessInfo[];

  private readonly admin?: boolean;

  constructor(user: User) {
    this.id = user.id;
    this.admin = user.admin;
    this.email = user.email;
    this.tenants = user.tenants;
  }

  get allowedReadTenants(): TenantAccessInfo[] {
    return this.tenants;
  }

  public setTenant(tenantId?: string): void {
    this.selectedTenantId = tenantId;
  }

  public isAdmin(): boolean {
    return Boolean(this.admin);
  }

  public hasReadAccessOnSelectedTenant(): boolean {
    return this.hasWriteAccessOnSelectedTenant() || this.checkAccessLevel('read');
  }

  public hasWriteAccessOnSelectedTenant(): boolean {
    return this.hasAdminAccessOnSelectedTenant() || this.checkAccessLevel('write');
  }

  public hasAdminAccessOnSelectedTenant(): boolean {
    return this.isAdmin() || this.checkAccessLevel('admin');
  }

  private checkAccessLevel(accessLevel: AccessLevel): boolean {
    const targetTenant = this.tenants.find((tenant) => tenant.id === this.selectedTenantId);
    if (targetTenant) {
      return targetTenant.accessLevel === accessLevel;
    }
    return false;
  }
}
