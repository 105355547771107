import { Modal, ModalBody, ModalFooter, ModalHeader, ModalButton } from 'baseui/modal';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedWithStyle } from '../../theme/theme';
import { copyToClipboard } from '../../utils/clipboard';
import { Textarea } from '../textarea/Textarea';

const StyledModalHeader = themedWithStyle(
  ModalHeader,
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingLargeSemiBold,
    color: $theme.colors.text100,
  }),
);

const StyledModalBody = themedWithStyle(
  ModalBody,
  ({ $theme }): StyleObject => ({
    marginBottom: $theme.sizes.scale0,
  }),
);

const StyledModalFooter = themedWithStyle(
  ModalFooter,
  ({ $theme }): StyleObject => ({
    borderTopWidth: $theme.sizes.scale0,
    paddingBottom: $theme.sizes.scale500,
  }),
);

export interface InvalidDevicesModalProps {
  deviceIds: string[];
  open?: boolean;
  onClose?: () => void;
}

export const InvalidDevicesModal: React.FC<InvalidDevicesModalProps> = (props) => {
  const { deviceIds, open, onClose } = props;

  function copyDeviceIds(): void {
    copyToClipboard({
      successMessage: 'Invalid IMEIs copied successfully',
      text: deviceIds.join(),
    });
  }

  return (
    <Modal
      onClose={onClose}
      size="default"
      isOpen={open}
      unstable_ModalBackdropScroll
      overrides={{
        Close: {
          style: {
            display: 'none',
          },
        },
        Dialog: {
          style: {
            width: '960px',
          },
        },
      }}
    >
      <StyledModalHeader>IMEIs error adding list</StyledModalHeader>
      <StyledModalBody>
        <Textarea value={deviceIds.join(', ')} disabled rows={15} />
      </StyledModalBody>
      <StyledModalFooter>
        <ModalButton kind="secondary" onClick={onClose}>
          Close
        </ModalButton>
        <ModalButton onClick={copyDeviceIds}>Copy list</ModalButton>
      </StyledModalFooter>
    </Modal>
  );
};
