export interface DeviceType {
  id: string;
  name: string;
  supportedActions: DeviceCommandInstruction[];
}

export enum DeviceCommandInstruction {
  honk = 'HONK',
  alarm = 'ALARM',
  volumeOn = 'VOLUME_ON',
  volumeOff = 'VOLUME_OFF',
  currentPosition = 'CURRENT_POSITION',
  info = 'INFO',
  firmware = 'FIRMWARE',
  batteryLock = 'BATTERY_LOCK',
  batteryUnlock = 'BATTERY_UNLOCK',
  unlock = 'UNLOCK',
  lock = 'LOCK',
  testing = 'TESTING',
  testingOff = 'TESTING_OFF',
  readConfig = 'READ_CONFIG',
  raw = 'RAW',
  lightsOn = 'LIGHTS_ON',
  lightsOff = 'LIGHTS_OFF',
  setMaxSpeed = 'SET_MAX_SPEED',
  normalMode = 'NORMAL_MODE',
  testMode = 'TEST_MODE',
  changeEndpoint = 'CHANGE_ENDPOINT',
  changePassword = 'CHANGE_PASSWORD',
  throttleOn = 'THROTTLE_ON',
  throttleOff = 'THROTTLE_OFF',
  mobilise = 'MOBILISE',
  immobilise = 'IMMOBILISE',
  wirelessChargerOn = 'WIRELESS_CHARGER_ON',
  wirelessChargerOff = 'WIRELESS_CHARGER_OFF',
  unlockAndMobilise = 'UNLOCK_AND_MOBILISE',
  lockAndImmobilise = 'LOCK_AND_IMMOBILISE',
  rebootIot = 'REBOOT_IOT',
  turnOffIot = 'TURN_OFF_IOT',
  cableLock = 'CABLE_LOCK',
  cableUnlock = 'CABLE_UNLOCK',
  saddleLock = 'SADDLE_LOCK',
  saddleUnlock = 'SADDLE_UNLOCK',
  tailboxLock = 'TAILBOX_LOCK',
  tailboxUnlock = 'TAILBOX_UNLOCK',
  updateHonkAudioFile = 'UPDATE_HONK_AUDIO_FILE',
  firmwareUpdate = 'UPDATE_FIRMWARE'
}

export interface FirmwareUpdate {
  readonly updateType: string;
  readonly url: string;
}

export interface DeviceCommand {
  readonly deviceId: string;
  readonly tenantId: string;
  readonly instruction: DeviceCommandInstruction;
  payload?: Record<string, unknown>;
}

export enum PacketType {
  deviceAck,
  deviceReport,
  serverRequest,
  serverAck
}

export interface Packet<T> {
  readonly type: PacketType;
  readonly imei: string;
  readonly instruction: string;
  readonly data: T;
  readonly meta: Record<string, unknown>;
}
