import { useStyletron } from 'baseui';
import { useObserver } from 'mobx-react';
import React, { useRef, useState } from 'react';

import { useStores } from '../../hooks/use-stores';
import { themedStyled, WunderTheme } from '../../theme/theme';
import { Alert } from '../alert/Alert';

import { InvalidDevicesModal } from './InvalidDevicesModal';

const Strong = themedStyled('span', ({ $theme }: { $theme: WunderTheme }) => ({
  ...$theme.typography.labelCaptionBold,
}));

function pluralize(word: string, count: number): string {
  return count === 1 ? word : `${word}s`;
}

const ButtonLink = themedStyled('button', () => ({
  backgroundColor: `transparent`,
  border: `none`,
  cursor: 'pointer',
  display: `inline`,
  fontSize: '0.75rem',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginTop: '-2px',
  padding: '0px 6px',
  textDecoration: 'underline',
}));

export const InvalidDevicesAlert: React.FC = () => {
  const { deviceStore } = useStores();
  const [modalOpen, setModalOpen] = useState(false);
  const deviceIdsRef = useRef<HTMLDivElement>(null);
  const [css] = useStyletron();

  function shouldShowMore(): boolean {
    const element = deviceIdsRef.current;

    if (element) {
      return element.offsetWidth < element.scrollWidth;
    }

    return false;
  }

  return useObserver(() => {
    const { addNewDevicesFinished, invalidDeviceIds } = deviceStore;
    const count = invalidDeviceIds.length;
    const open = addNewDevicesFinished && count > 0;

    return (
      <>
        <InvalidDevicesModal
          deviceIds={invalidDeviceIds}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <Alert open={open} onClose={() => deviceStore.clearCreatedAndInvalidDeviceIds()}>
          <Strong>
            {count} {pluralize('IMEI', count)} didn’t pass through the verification.
          </Strong>{' '}
          Please double check your {pluralize('code', count)} below:
          <br />
          <div
            className={css({ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' })}
          >
            <div
              ref={deviceIdsRef}
              className={css({
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              })}
            >
              "{invalidDeviceIds.join('", "')}"
            </div>
            {shouldShowMore() && <ButtonLink onClick={() => setModalOpen(true)}>more</ButtonLink>}
          </div>
        </Alert>
      </>
    );
  });
};
