import { DeviceCommandInstruction } from '../types';

const SUPPORTED_ACTIONS_OKAI = [
  DeviceCommandInstruction.alarm,
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.currentPosition,
  DeviceCommandInstruction.info,
  DeviceCommandInstruction.firmware,
  DeviceCommandInstruction.lightsOn,
  DeviceCommandInstruction.lightsOff,
  DeviceCommandInstruction.normalMode,
  DeviceCommandInstruction.testMode,
  DeviceCommandInstruction.changePassword,
  DeviceCommandInstruction.volumeOn,
  DeviceCommandInstruction.volumeOff,
  DeviceCommandInstruction.rebootIot,
  DeviceCommandInstruction.setMaxSpeed,
  DeviceCommandInstruction.firmwareUpdate,
];

export const OKAIYE0000 = {
  id: 'OKAIYE0000',
  name: 'Okai ES400',
  supportedActions: SUPPORTED_ACTIONS_OKAI,
};

export const OKAIYE0001 = {
  id: 'OKAIYE0001',
  name: 'Okai ES400A',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OKAI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.wirelessChargerOn,
    DeviceCommandInstruction.wirelessChargerOff,
  ],
};

export const OKAIYE0002 = {
  id: 'OKAIYE0002',
  name: 'Okai ES200D',
  supportedActions: SUPPORTED_ACTIONS_OKAI,
};

export const OKAIYE0003 = {
  id: 'OKAIYE0003',
  name: 'Okai EB200',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OKAI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.cableLock,
    DeviceCommandInstruction.cableUnlock,
  ],
};

export const OKAIYE0004 = {
  id: 'OKAIYE0004',
  name: 'Okai EB100',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OKAI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.cableLock,
    DeviceCommandInstruction.cableUnlock,
  ],
};

export const OKAIYE0005 = {
  id: 'OKAIYE0005',
  name: 'Okai ES400B',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OKAI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.wirelessChargerOn,
    DeviceCommandInstruction.wirelessChargerOff,
  ],
};

export const OKAI_DEVICES = [
  OKAIYE0000,
  OKAIYE0001,
  OKAIYE0002,
  OKAIYE0003,
  OKAIYE0004,
  OKAIYE0005,
];
