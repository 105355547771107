import React from 'react';

import { storesContext } from '../contexts';
import {
  UiStore,
  DeviceStore,
  TenantStore,
  DeviceConfigurationStore,
  UserStore,
  FeatureStore,
  DeviceLogStore,
} from '../stores';

export const useStores = (): {
  uiStore: UiStore;
  deviceStore: DeviceStore;
  tenantStore: TenantStore;
  deviceConfigurationStore: DeviceConfigurationStore;
  userStore: UserStore;
  featureStore: FeatureStore;
  deviceLogStore: DeviceLogStore;
} => React.useContext(storesContext);
