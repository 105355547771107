import { HONGJI_DEVICES } from './manufacturers/hongji';
import { OKAI_DEVICES } from './manufacturers/okai';
import { OMNI_DEVICES } from './manufacturers/omni';
import { SEGWAY_DEVICES } from './manufacturers/segway';
import { YADEA_DEVICES } from './manufacturers/yadea';
import { DeviceCommandInstruction, DeviceType } from './types';

export const SUPPORTED_ACTIONS_UNDEFINED_VEHICLE = [
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.alarm,
  DeviceCommandInstruction.volumeOn,
  DeviceCommandInstruction.volumeOff,
  DeviceCommandInstruction.currentPosition,
  DeviceCommandInstruction.info,
  DeviceCommandInstruction.firmware,
  DeviceCommandInstruction.batteryLock,
  DeviceCommandInstruction.batteryUnlock,
  DeviceCommandInstruction.unlock,
  DeviceCommandInstruction.lock,
  DeviceCommandInstruction.testing,
  DeviceCommandInstruction.testingOff,
  DeviceCommandInstruction.readConfig,
  DeviceCommandInstruction.raw,
  DeviceCommandInstruction.lightsOn,
  DeviceCommandInstruction.lightsOff,
  DeviceCommandInstruction.setMaxSpeed,
  DeviceCommandInstruction.normalMode,
  DeviceCommandInstruction.testMode,
  DeviceCommandInstruction.changeEndpoint,
  DeviceCommandInstruction.changePassword,
  DeviceCommandInstruction.throttleOn,
  DeviceCommandInstruction.throttleOff,
  DeviceCommandInstruction.mobilise,
  DeviceCommandInstruction.immobilise,
  DeviceCommandInstruction.wirelessChargerOn,
  DeviceCommandInstruction.wirelessChargerOff,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.rebootIot,
  DeviceCommandInstruction.cableLock,
  DeviceCommandInstruction.cableUnlock,
  DeviceCommandInstruction.saddleLock,
  DeviceCommandInstruction.saddleUnlock,
  DeviceCommandInstruction.tailboxLock,
  DeviceCommandInstruction.tailboxUnlock
];

export const UNDEFINED_VEHICLE_TYPE = {
  id: 'UNDEFINED_VEHICLE_TYPE',
  name: 'Undefined Vehicle',
  supportedActions: SUPPORTED_ACTIONS_UNDEFINED_VEHICLE
};

export const DEVICE_TYPES: DeviceType[] = [
  ...OKAI_DEVICES,
  ...OMNI_DEVICES,
  ...YADEA_DEVICES,
  ...SEGWAY_DEVICES,
  ...HONGJI_DEVICES
];
