export const SYNC_STATUS_TOTAL_DEVICES_NUMBER = 'sync-status-total-devices-number';
export const TENANT_SELECTOR = 'tenant-selector';
export const SIGN_OUT = 'sign-out';
export const SIGN_IN = 'sign-in';
export const GENERAL_COMMANDS = 'general-commands';
export const LOCK_COMMANDS = 'lock-commands';
export const CHANGE_DEVICE_CONFIGURATION = 'change-device-configuration';
export const SET_VEHICLE_TYPE = 'set-vehicle-type';
export const OPEN_LOGS = 'open-logs';
export const OPEN_ERROR_LOGS = 'open-error-logs';
export const DEVICE_LOGS_MODAL = 'device-logs-modal';
export const DEVICE_LOGS_MODAL_COPY_BUTTON = 'device-logs-modal-copy-button';
export const DEVICE_LOGS_MODAL_DATE_PICKER = 'device-logs-modal-date-picker';
