export const themeColors = {
  // interaction colors
  primary200: '#001999',
  primary100: '#0026E6',
  primary50: '#8596F3',
  primary10: '#F2F4FF',

  // text colors
  textOnPrimary: '#FFFFFF',
  text100: '#0E141A',
  text75: '#313F4D',
  text50: '#667380',
  text25: '#C2C7CC',
  textWhite: '#FFFFFF',
  textInactive: '#808080',

  // status colors
  success100: '#208050',
  success10: '#CEF2E0',
  warning100: '#FF8000',
  warning10: '#FFE9D9',
  error100: '#DC1E28',
  error10: '#FFEAE6',

  // background colors
  bg100: '#0E141A',
  bg90: '#262C31',
  bg75: '#2E3D4D',
  bg50: '#667380',
  bg25: '#C2C7CC',
  bg10: '#F0F1F2',
  bgWhite: '#FFFFFF',
};
