import { observable } from 'mobx';

import { logger } from '@wunder/tools-logger';

import { IOTClient } from '../client/interfaces';
import { DeviceConfiguration, DeviceConfigurationParams } from '../models/deviceConfiguration';

export class DeviceConfigurationStore {
  private tenantId: string | null = null;

  @observable deviceConfigurations: DeviceConfiguration[] = [];

  @observable ready = false;

  constructor(readonly iotClient: IOTClient) {}

  async initData(tenantId: string): Promise<void> {
    if (this.tenantId !== tenantId) {
      this.tenantId = tenantId;
      this.ready = false;
      try {
        const response = await this.iotClient.listDeviceConfigurations(tenantId);
        if (response.success && response.deviceConfigs) {
          this.deviceConfigurations = response.deviceConfigs;
        }
        this.ready = true;
      } catch (err) {
        logger.error({ err }, "deviceConfiguration can't be fetched");
      }
    }
  }

  getDeviceConfiguration(id: string): DeviceConfiguration | undefined {
    return this.deviceConfigurations?.find((configDoc) => {
      return configDoc?.id === id;
    });
  }

  getDeviceConfigurationName(id: string): string | undefined {
    return this.getDeviceConfiguration(id)?.name;
  }

  async updateConfig(newConfiguration: DeviceConfigurationParams): Promise<void> {
    if (this.tenantId && newConfiguration.id) {
      try {
        const response = await this.iotClient.updateDeviceConfiguration(
          this.tenantId,
          newConfiguration.id,
          newConfiguration,
        );
        if (response.success) {
          this.deviceConfigurations = this.deviceConfigurations.map((config) => {
            if (response.deviceConfig && config.id === response.deviceConfig?.id)
              return response.deviceConfig;
            return config;
          });
        }
      } catch (err) {
        logger.error({ err }, "deviceConfiguration can't be updated");
      }
    }
  }

  async addNewConfiguration(
    configuration: DeviceConfigurationParams,
  ): Promise<DeviceConfiguration | void> {
    if (this.tenantId) {
      try {
        const response = await this.iotClient.createDeviceConfiguration(
          this.tenantId,
          configuration,
        );
        if (response.success && response.deviceConfig) {
          this.deviceConfigurations?.push(response.deviceConfig);
        }
      } catch (err) {
        logger.error({ err }, "deviceConfiguration can't be created");
      }
    }
  }

  async setConfigurationOnDevices(
    deviceConfigurationId: string,
    deviceIds: string[],
  ): Promise<DeviceConfiguration | void> {
    if (this.tenantId) {
      try {
        await this.iotClient.setConfigurationOnDevices(
          this.tenantId,
          deviceConfigurationId,
          deviceIds,
        );
      } catch (err) {
        logger.error({ err }, 'can not set device configuration on deviceIds');
      }
    }
  }
}
