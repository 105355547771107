import * as React from 'react';

import { useStores } from '../../hooks/use-stores';
import { DeviceLogStore } from '../../stores';

const REFRESH_INTERVAL_SEC = 1000 * 30;

export const useLogData = ({
  deviceId,
  dates,
  limit,
  refresh,
  page,
  pageSize,
}: {
  deviceId: string;
  dates: Date[];
  limit?: number;
  refresh?: boolean;
  page: number;
  pageSize: number;
}): DeviceLogStore => {
  const { deviceLogStore, tenantStore } = useStores();

  const getLogs = async () => {
    const [startDate, endDate] = dates;

    if (deviceId && startDate && endDate) {
      const tenantId = tenantStore.getTenantId() || '';

      deviceLogStore.fetchDeviceLog({
        dates,
        deviceId,
        limit: pageSize,
        page,
        tenantId,
      });
    }
  };

  React.useEffect(() => {
    getLogs();

    let interval: NodeJS.Timeout;

    if (refresh) {
      interval = setInterval(() => {
        getLogs();
      }, REFRESH_INTERVAL_SEC);
    }

    return () => interval && clearInterval(interval);
  }, [deviceId, dates, limit, refresh, page, pageSize]);

  return deviceLogStore;
};
