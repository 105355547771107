import { FormControl } from 'baseui/form-control';
import { Select, SIZE, OnChangeParams } from 'baseui/select';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { DEVICE_TYPES as allSupportedDeviceTypes } from '@wunder/tools-iot-connector-device-types';

import { themedStyled } from '../../theme/theme';

const Row = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    marginBottom: $theme.sizes.scale100,
    marginLeft: $theme.sizes.scale400,
    marginRight: $theme.sizes.scale400,
    marginTop: $theme.sizes.scale100,
  }),
);

export const DeviceConfigurationType = ({
  value,
  disabled = false,
  error,
  label,
  tooltip,
  onChange,
}: {
  value?: string;
  disabled?: boolean;
  error?: string;
  label: string;
  tooltip?: string;
  onChange: (value: unknown) => void;
}): JSX.Element => {
  return (
    <Row>
      <FormControl label={label} error={Boolean(error)} caption={error || tooltip}>
        <Select
          size={SIZE.compact}
          options={allSupportedDeviceTypes.map((type) => ({ id: type.id, label: type.name }))}
          clearable={false}
          searchable={false}
          value={[{ id: value }]}
          disabled={disabled}
          onChange={(params: OnChangeParams) => {
            onChange(params.value[0].id);
          }}
          overrides={{
            ControlContainer: {
              style: ({ $theme }) => {
                return {
                  backgroundColor: `${$theme.colors.white}`,
                };
              },
            },
          }}
        />
      </FormControl>
    </Row>
  );
};
