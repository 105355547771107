import { action, observable } from 'mobx';
import Cookie from 'mobx-cookie';

import { IOTClient } from '../client/interfaces';
import { TenantConfig, TenantDocument } from '../models';
import { TenantAccessInfo } from '../models/userDocument';

import { UserStore } from './userStore';

export class TenantStore {
  @observable currentTenant?: TenantDocument;

  @observable tenants: Array<TenantAccessInfo> | null = null;

  readonly cookie = new Cookie('tenantId');

  readonly userStore: UserStore;

  constructor(userStore: UserStore, readonly iotClient: IOTClient) {
    this.userStore = userStore;
  }

  @action
  public async setCurrentTenant(tenantId: string): Promise<void> {
    const { success, tenant } = await this.iotClient.getTenant(tenantId);
    if (success && tenant) {
      this.currentTenant = new TenantDocument(tenant);
      this.setCookie(tenantId);
      this.userStore.currentUser?.setTenant(tenant.id);
      this.iotClient.setBaseURL(this.currentTenant.iotConnector.baseUrl);
    }
  }

  public getTenantId(): string | undefined {
    if (this.currentTenant) {
      return this.currentTenant.id;
    }

    if (
      this.cookie.value &&
      this.tenants?.find(({ id }: { id: string }) => id === this.cookie.value)
    ) {
      return this.cookie.value;
    }

    if (this.tenants && this.tenants.length > 0) {
      return this.tenants[0].id;
    }

    return undefined;
  }

  async initData(tenants: TenantAccessInfo[]): Promise<void> {
    if (!this.tenants) {
      this.tenants = tenants;
      const initialTenantFromCookie = this.tenants.find(
        (tenant) => tenant.id === this.cookie.value,
      );
      if (initialTenantFromCookie) {
        this.iotClient.setBaseURL(initialTenantFromCookie.apiUrl);
        await this.setCurrentTenant(initialTenantFromCookie.id);
      }
      if (!this.currentTenant) {
        const firstTenantId = this.tenants[0]?.id;
        if (firstTenantId) {
          this.iotClient.setBaseURL(this.tenants[0].apiUrl);
          await this.setCurrentTenant(firstTenantId);
        }
      }
    }
  }

  async updateTenant(tenantConfig: TenantConfig): Promise<void> {
    const { success, tenant } = await this.iotClient.updateTenant(tenantConfig);
    if (success && tenant) {
      this.currentTenant = new TenantDocument(tenant);
    }
  }

  private setCookie(tenantId: string): void {
    this.cookie.set(tenantId);
  }
}
