import { FormField, TabbedFormField, FieldType } from '../formHelper/formFieldTypes';

export const generalSettingsFields: FormField[] = [
  {
    name: 'name',
    translationKey: 'tenant.settings.name',
    type: 'text',
  },
  {
    name: 'devicePassword',
    translationKey: 'tenant.settings.devicePassword',
    type: 'text',
  },
  {
    name: 'deviceUpdateTimeout',
    translationKey: 'tenant.settings.deviceUpdateTimeout',
    type: 'number',
    options: {
      min: 25,
      max: 120,
      step: 1,
    },
  },
  {
    name: 'enableAutomaticDeviceCreation',
    translationKey: 'tenant.settings.enableAutomaticDeviceCreation',
    type: 'switch',
  },
];

export const gatewayFields: FormField[] = [
  {
    name: 'endpoint.address',
    translationKey: 'tenant.settings.address',
    type: 'disabled',
  },
  {
    name: 'endpoint.port',
    translationKey: 'tenant.settings.port',
    type: 'disabled',
  },
];

export const credentialFields = (isAdmin: boolean): FormField[] => {
  const baseUrlType: FieldType = isAdmin ? 'text' : 'disabled';
  const allowedDomains = ['backend.fleetbird.eu', 'backend.fleetbird-dev.de'];

  return [
    {
      label: 'Fleet webhook',
      name: 'fleet',
      type: 'group',
      fields: [
        {
          name: 'webhookApiKey',
          translationKey: 'tenant.settings.fleet.webhookApiKey',
          type: 'text',
        },
        {
          name: 'webhookUrl',
          translationKey: 'tenant.settings.fleet.webhookUrl',
          type: 'text',
          validation: (value: unknown): string | undefined => {
            if (value && !allowedDomains.some((domain) => (value as string).includes(domain))) {
              return `The Fleet Webhook Url must include "${allowedDomains.join('" or "')}".`;
            }
            return undefined;
          },
        },
      ],
    },
    {
      label: 'Wunder Tools IoT Connector',
      name: 'iotConnector',
      type: 'group',
      fields: [
        {
          name: 'baseUrl',
          translationKey: 'tenant.settings.connector.baseUrl',
          type: baseUrlType as any,
        },
        {
          name: 'apiKey',
          translationKey: 'tenant.settings.connector.apiKey',
          type: 'text',
        },
      ],
    },
  ];
};

export const encryptionFields: FormField[] = [
  {
    name: 'deviceEncryptionKey',
    translationKey: 'tenant.settings.deviceEncryptionKey',
    type: 'textarea',
  },
  {
    name: 'deviceEncryptionIv',
    translationKey: 'tenant.settings.deviceEncryptionIv',
    type: 'textarea',
  },
];

export const formFields = (isAdmin: boolean): TabbedFormField[] => [
  { formFields: generalSettingsFields, tabLabel: 'General' },
  { formFields: credentialFields(isAdmin), tabLabel: 'Credentials' },
  { formFields: gatewayFields, tabLabel: 'Gateway' },
  { formFields: encryptionFields, tabLabel: 'Encryption' },
];
