import { MenuAdapter } from 'baseui/list';
import { StatefulMenu } from 'baseui/menu';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { ChevronRightIcon } from '../../theme/icons';
import { themedUseStyletron as useStyletron, themedStyled } from '../../theme/theme';
import { Button } from '../button/Button';

import { Container, Headline, Footer } from './DeviceConfigurationHelper';

export interface ListItem {
  title: string;
  onItemSelect: (item: ListItem) => void;
}

interface DeviceConfigurationListProps {
  listItems: ListItem[];
  editable?: boolean;
  onButtonClick: () => void;
}

const Content = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    background: $theme.colors.bgWhite,
    flexGrow: 1,
  }),
);

export const DeviceConfigurationList = ({
  listItems,
  editable = false,
  onButtonClick,
}: DeviceConfigurationListProps): JSX.Element | null => {
  const [, theme] = useStyletron();

  return (
    <Container>
      <Headline>Manage Device Configurations</Headline>

      <Content>
        {listItems.length > 0 && (
          <StatefulMenu
            items={listItems}
            onItemSelect={(entry): void => entry.item.onItemSelect(entry.item)}
            overrides={{
              List: {
                style: {
                  boxShadow: 'none',
                  ...theme.typography.contentBodyRegular,
                  color: theme.colors.text100,
                },
              },
              Option: {
                props: {
                  overrides: {
                    ListItem: {
                      component: React.forwardRef(
                        (props: any, ref): JSX.Element => (
                          <MenuAdapter
                            {...props}
                            ref={ref}
                            endEnhancer={(): JSX.Element => <ChevronRightIcon />}
                          >
                            {props.item.title}
                          </MenuAdapter>
                        ),
                      ),
                    },
                  },
                },
              },
            }}
          />
        )}
      </Content>
      <Footer>
        <Button variant="primary" size="large" onClick={onButtonClick} disabled={!editable}>
          Create new Configuration
        </Button>
      </Footer>
    </Container>
  );
};
