import React from 'react';

import { FormikValues } from 'formik';
import {
  CloseHandler,
  DialogFormBase,
  DialogFormChildrenProps,
  generateFieldNames,
  SubmitHandler
} from './DialogFormBase';
import { FormField } from './formFieldTypes';
import { renderFields } from './renderFields';

interface DialogFormProps<V> {
  fields: FormField[];
  initialValues: V;
  loading: boolean;
  onClose: CloseHandler;
  onSubmit: SubmitHandler<V>;
  open: boolean;
  title: string;
  readonly?: boolean;
  size?: 'default' | 'large';
}

export function DialogForm<V extends FormikValues>({
  fields,
  initialValues,
  loading,
  onClose,
  onSubmit,
  open,
  title,
  readonly,
  size = 'default'
}: DialogFormProps<V>): JSX.Element {
  return (
    <DialogFormBase
      initialValues={initialValues}
      loading={loading}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      title={title}
      readonly={readonly}
      size={size}
    >
      {(props: DialogFormChildrenProps<V>) => renderFields(generateFieldNames(fields), props)}
    </DialogFormBase>
  );
}
