import { DeviceCommandInstruction } from '../types';

const SUPPORTED_ACTIONS_YADEA = [
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.lock,
  DeviceCommandInstruction.unlock,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.saddleUnlock,
  DeviceCommandInstruction.saddleLock,
  DeviceCommandInstruction.rebootIot,
];

export const YADEYA0000 = {
  id: 'YADEYA0000',
  name: 'Yadea G5',
  supportedActions: SUPPORTED_ACTIONS_YADEA,
};

export const YADEYA0001 = {
  id: 'YADEYA0001',
  name: 'Yadea G5L',
  supportedActions: [
    ...SUPPORTED_ACTIONS_YADEA,
    DeviceCommandInstruction.tailboxLock,
    DeviceCommandInstruction.tailboxUnlock,
    DeviceCommandInstruction.currentPosition,
  ],
};

export const YADEA_DEVICES = [YADEYA0000, YADEYA0001];
