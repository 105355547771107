import { toaster } from 'baseui/toast';
import React from 'react';

import { Filter, FilterTypes } from '../../stores/deviceStore';
import {
  DeviceFilter,
  DeviceFilterCheckboxGroup,
  DeviceFilterTextArea
} from '../deviceFilter/DeviceFilter';
import { ActiveFilterValueMap } from '../deviceListHeader/DeviceListBarFilterTags';

interface DeviceListTableFilterProps {
  activeFilter: ActiveFilterValueMap;
  availableTypes: { name: string; value?: string }[];
  availableConfigurations: { name: string; value?: string }[];
  availableFirwareVersions: { name: string; value?: string }[];
  onApply: (newFilter: Filter[]) => void;
  onReset: () => void;
}

export const DeviceListTableFilter = ({
  activeFilter,
  availableTypes,
  availableConfigurations,
  availableFirwareVersions,
  onApply,
  onReset
}: DeviceListTableFilterProps): JSX.Element => {
  const [textareaDeviceIds, setTextareaDeviceIds] = React.useState(
    activeFilter.deviceIds.map((deviceId) => deviceId.name).join(',')
  );
  const [types, setTypes] = React.useState<string[]>(
    activeFilter.deviceTypes.map((deviceType) => deviceType.name)
  );
  const [configurations, setConfigurations] = React.useState<string[]>(
    activeFilter.deviceConfigurations.map((deviceConfiguration) => deviceConfiguration.name)
  );
  const [selectedFirmwareVersions, setSelectedFirmwareVersions] = React.useState<string[]>([]);

  React.useEffect(() => {
    setTextareaDeviceIds(activeFilter.deviceIds.map((deviceId) => deviceId.name).join(','));
    setTypes(activeFilter.deviceTypes.map((deviceType) => deviceType.name));
    setConfigurations(
      activeFilter.deviceConfigurations.map((deviceConfiguration) => deviceConfiguration.name)
    );
  }, [activeFilter]);

  const applyFilters = (): void => {
    const newFilters: Filter[] = [];
    const deviceIds =
      textareaDeviceIds.length > 0
        ? [...new Set(textareaDeviceIds.replace(/\s/g, '').split(','))]
        : undefined;
    const activeTypeFilters = types;
    const activeConfigurationFilters = configurations;
    const typeIds = activeTypeFilters;
    const configurationRefIds = activeConfigurationFilters;

    if (deviceIds) {
      if (deviceIds.length > 500) {
        toaster.warning(<>{'Device IDs should be less than 500.'}</>, {});
        return;
      }
      newFilters.push({ value: deviceIds, type: FilterTypes.DeviceIds });
    }

    if (typeIds) {
      newFilters.push({ value: typeIds, type: FilterTypes.DeviceTypeIds });
    }

    if (configurationRefIds) {
      newFilters.push({ value: configurationRefIds, type: FilterTypes.ConfigurationRefIds });
    }

    newFilters.push({ value: selectedFirmwareVersions, type: FilterTypes.FirmwareVersions });

    onApply(newFilters);
  };

  const resetAllFilter = (): void => {
    setConfigurations([]);
    setTypes([]);
    setTextareaDeviceIds('');
    onReset();
  };

  const shouldRender = availableTypes && availableConfigurations;

  return (
    shouldRender && (
      <DeviceFilter applyFilters={applyFilters} resetAllFilters={resetAllFilter}>
        <DeviceFilterTextArea
          label='DeviceIDs'
          value={textareaDeviceIds}
          onChange={(event): void => setTextareaDeviceIds(event.currentTarget.value)}
          placeholder="Enter device ID's separated by comma, e.g.: 99000086247, 35175605152, ..."
        />
        <DeviceFilterCheckboxGroup
          label='Configuration'
          value={configurations}
          options={availableConfigurations}
          onChange={(event): void => {
            const { checked, value } = event.currentTarget;
            if (!value) {
              setConfigurations([]);
            } else if (checked) {
              setConfigurations([...configurations, value]);
            } else {
              setConfigurations(configurations.filter((item) => item !== value));
            }
          }}
        />
        <DeviceFilterCheckboxGroup
          label='Model'
          value={types}
          options={availableTypes}
          onChange={(event): void => {
            const { checked, value } = event.currentTarget;
            if (!value) {
              setTypes([]);
            } else if (checked) {
              setTypes([...types, value]);
            } else {
              setTypes(types.filter((item) => item !== value));
            }
          }}
        />
        <DeviceFilterCheckboxGroup
          label='Firmware'
          value={selectedFirmwareVersions}
          options={availableFirwareVersions}
          onChange={(event): void => {
            const { checked, value } = event.currentTarget;
            if (!value) {
              setSelectedFirmwareVersions([]);
            } else if (checked) {
              setSelectedFirmwareVersions([...selectedFirmwareVersions, value]);
            } else {
              setSelectedFirmwareVersions(
                selectedFirmwareVersions.filter((item) => item !== value)
              );
            }
          }}
        />
      </DeviceFilter>
    )
  );
};
