import { BaseProvider } from 'baseui';
import { StyledSpinnerNext } from 'baseui/spinner';
import { ToasterContainer } from 'baseui/toast';
import { useObserver } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';

import { ErrorBoundary } from './components/ErrorBoundary';
import { DeviceList } from './components/deviceList/DeviceList';
import { Header } from './components/header/Header';
import { Login } from './components/login/Login';
import { ApiDocumentation } from './components/apiDocumentation/ApiDocumentation';
import { useStores } from './hooks/use-stores';
import { wunderTheme } from './theme/theme';
import './i18n';

const engine = new Styletron();
const debug = process.env.NODE_ENV === 'production' ? undefined : new DebugEngine();

const SuspenseFallback = () => (
  <StyletronProvider value={engine} debug={debug}>
    <StyledSpinnerNext />
  </StyletronProvider>
);

const App = (): JSX.Element | null =>
  useObserver((): JSX.Element | null => {
    const { userStore } = useStores();
    const { currentUser } = userStore;
    const { t } = useTranslation();

    userStore.fetchCurrentUser();

    document.body.style.margin = '0';
    document.body.style.backgroundColor = wunderTheme.colors.bg10;

    const handleLogout = (): void => {
      if (currentUser) {
        userStore.removeCurrentUser();
      }
    };

    return (
      <ErrorBoundary>
        <StyletronProvider value={engine} debug={debug}>
          <BaseProvider theme={wunderTheme}>
            <Helmet>
              <title>{currentUser ? t('default.pageTitle') : t('loginPage.pageTitle')}</title>
            </Helmet>

            <ToasterContainer>
              {currentUser ? (
                <Router>
                  <Header handleLogout={handleLogout} version={process.env.VERSION} />
                  <Switch>
                    <Route path="/" exact>
                      <DeviceList />
                    </Route>
                    <Route path="/docs" exact>
                      <ApiDocumentation />
                    </Route>
                  </Switch>
                </Router>
              ) : (
                <Login />
              )}
            </ToasterContainer>
          </BaseProvider>
        </StyletronProvider>
      </ErrorBoundary>
    );
  });

ReactDOM.render(
  <Suspense fallback={<SuspenseFallback />}>
    <App />
  </Suspense>,
  document.getElementById('app'),
);
