import { StyletronComponent } from 'styletron-react';
import { themedStyled, WunderTheme } from '../../theme/theme';

export const Bar: StyletronComponent<any> = themedStyled(
  'div',
  ({ $theme }: { $theme: WunderTheme }) => ({
    alignItems: 'center',
    backgroundColor: $theme.colors.bg10,
    boxSizing: 'border-box',
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-start',
    padding: '0px 14px',
    width: '100%'
  })
);
