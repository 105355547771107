export class TokenHandler {
  private static TOKEN_KEY: string = 'TOKEN';

  public static getToken(): string | undefined {
    return window.localStorage.getItem(TokenHandler.TOKEN_KEY) || '';
  }

  public static setToken(token: string) {
    window.localStorage.setItem(TokenHandler.TOKEN_KEY, token);
  }

  public static removeToken() {
    window.localStorage.removeItem(TokenHandler.TOKEN_KEY);
  }
}
