import path from 'path';

import {
  CreateDeviceConfigurationParams,
  DeviceParams,
  TenantConfig,
  UpdateDeviceConfigurationParams,
} from '../../models';
import { getDeviceLogsSamples } from '../../utils/deviceLogs';
import { TokenHandler } from '../../utils/tokenHandler';
import {
  IOTClient,
  DeviceConfigurationsResponse,
  DeviceConfigurationResponse,
  IOTClientResponse,
  TenantResponse,
  ListDevicesResponse,
  ListEventsResponse,
  CreateDeviceResponse,
  UpdateDeviceResponse,
  DeleteDeviceResponse,
  GetDevicesStatusResponse,
  FilterResponse,
  FetchDeviceLogsResponse,
  FetchDocumentationsResponse,
} from '../interfaces';

export class IOTApiClient implements IOTClient {
  private baseURL!: string;

  public setBaseURL(apiUrl: string): void {
    this.baseURL = apiUrl.split('/').splice(0, 3).join('/') + '/iot-api';
    return;
  }

  public async listDeviceConfigurations(tenantId: string): Promise<DeviceConfigurationsResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/configurations`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const deviceConfigs = await response.json();
      return { success: true, deviceConfigs };
    } catch (err) {
      return {
        error: 'Error occurred when listing device configurations',
        key: 'ErrorDeviceConfigurationListRequestFailed',
        success: false,
      };
    }
  }

  public async createDeviceConfiguration(
    tenantId: string,
    newDeviceConfiguration: CreateDeviceConfigurationParams,
  ): Promise<DeviceConfigurationResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/configurations`);
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify(newDeviceConfiguration),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'POST',
      });
      const deviceConfig = await response.json();
      return { success: true, deviceConfig };
    } catch (err) {
      return {
        error: 'Error occurred when creating new device configuration',
        key: 'ErrorDeviceConfigurationCreateRequestFailed',
        success: false,
      };
    }
  }

  public async updateDeviceConfiguration(
    tenantId: string,
    deviceConfigurationId: string,
    updatedDeviceConfiguration: UpdateDeviceConfigurationParams,
  ): Promise<DeviceConfigurationResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(
      url.pathname,
      `/tenants/${tenantId}/configurations/${deviceConfigurationId}`,
    );
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify(updatedDeviceConfiguration),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'PATCH',
      });
      const deviceConfig = await response.json();
      return { success: true, deviceConfig };
    } catch (err) {
      return {
        error: `Error occurred when updating device configuration for ${deviceConfigurationId}`,
        key: 'ErrorDeviceConfigurationUpdateRequestFailed',
        success: false,
      };
    }
  }

  public async setConfigurationOnDevices(
    tenantId: string,
    deviceConfigurationId: string,
    deviceIds: string[],
  ): Promise<IOTClientResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/configuration`);
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify({
          deviceConfigurationId,
          deviceIds,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'PUT',
      });
      return { success: response.status == 200 };
    } catch (err) {
      return {
        error: `Error occurred when setting device configuration on devices ${deviceIds}`,
        key: 'ErrorDeviceConfigurationSetOnDevicesRequestFailed',
        success: false,
      };
    }
  }

  public async getTenant(tenantId: string): Promise<TenantResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const tenant = await response.json();
      return { success: true, tenant };
    } catch (err) {
      return {
        error: 'Error occurred when fetching tenant',
        key: 'ErrorTenantGetRequestFailed',
        success: false,
      };
    }
  }

  public async updateTenant(tenantConfig: TenantConfig): Promise<TenantResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantConfig.id}`);
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify(tenantConfig),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'PATCH',
      });
      const tenant = await response.json();
      return { success: true, tenant };
    } catch (err) {
      return {
        error: 'Error occurred when fetching tenant',
        key: 'ErrorTenantGetRequestFailed',
        success: false,
      };
    }
  }

  public async createDevice(tenantId: string, deviceId: string): Promise<CreateDeviceResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices`);
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify({ id: deviceId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'POST',
      });
      const { device } = await response.json();
      return { success: true, device };
    } catch (err) {
      return {
        error: 'Error occurred when creating device',
        key: 'ErrorDeviceCreateRequestFailed',
        success: false,
      };
    }
  }

  public async updateDevice(
    tenantId: string,
    deviceId: string,
    params: Omit<DeviceParams, 'id' | 'tenantId'>,
  ): Promise<UpdateDeviceResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/${deviceId}`);
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'PATCH',
      });
      const device = await response.json();
      return { success: true, device };
    } catch (err) {
      return {
        error: 'Error occurred when updating device',
        key: 'ErrorDeviceUpdateRequestFailed',
        success: false,
      };
    }
  }

  public async deleteDevice(deviceId: string, tenantId: string): Promise<DeleteDeviceResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/${deviceId}`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'DELETE',
      });
      const device = await response.json();
      return { success: true, device };
    } catch (err) {
      return {
        error: 'Error occurred when deleting device',
        key: 'ErrorDeviceDeleteRequestFailed',
        success: false,
      };
    }
  }

  public async listDevices(
    tenantId: string,
    page: number,
    pageSize: number,
    sortBy: string,
    orderBy: string,
    filters: {
      type: string;
      value: string[];
    }[],
  ): Promise<ListDevicesResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices`);
    url.searchParams.append('page', `${page}`);
    url.searchParams.append('pageSize', `${pageSize}`);
    url.searchParams.append('sortBy', `${sortBy}`);
    url.searchParams.append('orderBy', `${orderBy}`);
    filters.forEach((filter) => {
      const value = filter.value ? filter.value.join(',') : '1';
      url.searchParams.append(filter.type, value);
    });
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const { devices, totalDevices } = await response.json();
      return { success: true, devices, page, pageSize, totalDevices };
    } catch (err) {
      return {
        error: 'Error occurred when fetching devices',
        key: 'ErrorDeviceListRequestFailed',
        success: false,
      };
    }
  }

  public async getDevicesStatus(tenantId: string): Promise<GetDevicesStatusResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/status`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const data = await response.json();
      return { success: true, devicesStatus: data };
    } catch (err) {
      return {
        error: 'Error occurred when fetching devices status',
        key: 'ErrorDevicesGetStatusRequestFailed',
        success: false,
      };
    }
  }

  public async listEvents(
    tenantId: string,
    deviceId: string,
    page: number,
    pageSize: number,
  ): Promise<ListEventsResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/${deviceId}/errors`);
    url.searchParams.append('page', `${page}`);
    url.searchParams.append('pageSize', `${pageSize}`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const { errors } = await response.json();
      return { success: true, events: errors, page, pageSize };
    } catch (err) {
      return {
        error: 'Error occurred when fetching events',
        key: 'ErrorEventsListRequestFailed',
        success: false,
      };
    }
  }

  public async getFilters(tenantId: string, filterType: string): Promise<FilterResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/filters/${filterType}`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const filters = await response.json();
      return filters;
    } catch (err) {
      throw new Error('Error occurred when fetching unique filters');
    }
  }

  public async fetchDocumentations(tenantId: string): Promise<FetchDocumentationsResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, '/api/documentations');
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
          'x-documentation-server-url': this.baseURL.replace('/iot-api', ''),
          'x-tenant-id': tenantId,
        },
        method: 'GET',
      });
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        error: 'Error occurred when fetching documentation',
        key: 'ErrorDocumentationFetchRequestFailed',
        success: false,
      };
    }
  }

  public async fetchDeviceLogs(
    tenantId: string,
    deviceId: string,
    startDate: string,
    endDate: string,
    page: number,
    limit: number,
  ): Promise<FetchDeviceLogsResponse> {
    if (process.env.NODE_ENV === 'development') {
      return Promise.resolve({
        success: true,
        logs: getDeviceLogsSamples(deviceId, 5),
        page: 1,
        limit: 50,
      });
    }
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, `/tenants/${tenantId}/devices/${deviceId}/logs`);
    url.searchParams.append('startDate', `${startDate}`);
    url.searchParams.append('endDate', `${endDate}`);
    url.searchParams.append('page', `${page}`);
    url.searchParams.append('limit', `${limit}`);
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const data = await response.json();
      return { success: true, logs: data, page, limit };
    } catch (err) {
      return {
        error: 'Error occurred when fetching events',
        key: 'ErrorEventsListRequestFailed',
        success: false,
      };
    }
  }
}
