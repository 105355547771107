export function formatDateForTableEntries(date: Date | undefined): string | undefined {
  if (!date) return undefined;

  const year = date.getUTCFullYear();
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);
  const hour = `0${date.getUTCHours()}`.slice(-2);
  const minute = `0${date.getUTCMinutes()}`.slice(-2);

  return `${year}-${month}-${day} / ${hour}:${minute}`;
}
