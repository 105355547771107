import bunyan, { Stream, WriteFn } from 'bunyan';
import PrettyStream from 'bunyan-prettystream';

import { createStream } from './gcpLoggingStream';

const LOGGER_NAME = 'IoT-Connector';
const LOGGER_ENV = process.env.ENVIRONMENT ?? 'dev';
const LOG_LEVEL = process.env.LOG_LEVEL ?? 'info';

const streams: Stream[] = [];

/**
 * https://github.com/trentm/node-bunyan#core-fields
 */
export interface CoreFields {
  v: number;
  level: number;
  name: string;
  hostname: string;
  pid: number;
  time: string;
  msg: string;
}

export interface Record extends CoreFields {
  err?: Error;
}

if (process.env.TARGET === 'WEB') {
  class BrowserStream {
    write(rec: Record) {
      console.log('[%s] %s: %s', rec.time, bunyan.nameFromLevel[rec.level], rec.msg);
    }
  }

  streams.push({
    level: LOG_LEVEL as bunyan.LogLevel,
    stream: new BrowserStream() as WriteFn,
    type: 'raw'
  });
} else if (LOGGER_ENV === 'dev') {
  const prettyStdOut = new PrettyStream();
  prettyStdOut.pipe(process.stdout);

  streams.push({
    level: LOG_LEVEL as bunyan.LogLevel,
    stream: prettyStdOut,
    type: 'raw'
  });
} else {
  streams.push(createStream(LOG_LEVEL as bunyan.LogLevel, process.stdout));
}

export const logger = bunyan.createLogger({
  name: LOGGER_NAME,
  serializers: {
    /**
     * Do not serialize errors since we have to make sure they are actual Error instances
     * https://help.sentry.io/sdks/configuration/why-am-i-seeing-events-with-non-error-exception-or-promise-rejection-captured-with-keys-using-the-javascript-sdk/
     */
    // err: bunyan.stdSerializers.err,
    req: bunyan.stdSerializers.req,
    res: bunyan.stdSerializers.res
  },
  streams
});

export const deviceLogger = logger.child({ service: 'devices' });

export interface LoggerAltInterface {
  info(object: object, message: string): void;
  warn(object: object, message: string): void;
  error(object: object, message: string): void;
}

export class LoggerAlt implements LoggerAltInterface {
  private logger: bunyan;

  constructor(bunyanLogger: bunyan) {
    this.logger = bunyanLogger;
  }

  public info(object: object, message: string): void {
    this.logger.info(object, message);
  }

  public warn(object: object, message: string): void {
    this.logger.warn(object, message);
  }

  public error(object: object, message: string): void {
    this.logger.error(object, message);
  }
}
