import React from 'react';

import { InvalidDevicesAlert } from '../addNewDevices/InvalidDevicesAlert';

export const DeviceListHeaderAlerts: React.FC = () => {
  return (
    <>
      <InvalidDevicesAlert />
    </>
  );
};
