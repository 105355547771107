import React from 'react';

import { themedUseStyletron as useStyletron, themedStyled } from '../../theme/theme';

import { getColor, getFillDisabled, ButtonVariant } from './buttonStyles';

type IconProps = {
  $hovered: boolean;
  $disabled: boolean;
};

type ButtonIconProps = {
  icon: React.ComponentType<unknown>;
  variant: ButtonVariant;
  disabled: boolean;
  hovered: boolean;
};

export const ButtonIcon = ({ icon, variant, disabled, hovered }: ButtonIconProps): JSX.Element => {
  const [, theme] = useStyletron();
  const styles = {
    height: theme.sizes.scale400,
    marginBotton: theme.sizes.scale200,
    marginLeft: `-${theme.sizes.scale200}`,
    width: theme.sizes.scale400,
  };

  const getFillColor = ($disabled: boolean, $hovered: boolean): { fill: string } => {
    if (variant === 'toolbar') {
      return { fill: $hovered ? theme.colors.primary100 : theme.colors.textWhite };
    }
    return { fill: $disabled ? getFillDisabled(variant) : getColor(variant) };
  };

  const StyledIcon = themedStyled(icon, ({ $disabled, $hovered }: IconProps) => ({
    ...styles,
    ...getFillColor($disabled, $hovered),
  }));

  return <StyledIcon $disabled={disabled} $hovered={hovered} />;
};
