import { FormControl } from 'baseui/form-control';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import React, { useState } from 'react';
import { StyleObject } from 'styletron-react';

import { FilterIcon } from '../../theme/icons';
import { themedStyled, themedUseStyletron as useStyletron } from '../../theme/theme';
import { Button } from '../button/Button';
import { Checkbox } from '../checkbox/Checkbox';
import { Textarea } from '../textarea/Textarea';

export type OptionType = {
  name: string;
  value?: string;
};

type TextareaType = {
  label: string;
  value: string;
  placeholder: string;
  onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
};

type CheckboxType = {
  label: string;
  value?: string[];
  options: OptionType[];
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

type DeviceFilterProps = {
  resetAllFilters: () => void;
  applyFilters: () => void;
  children?: React.ReactNodeArray;
};

const PopoverContainer = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    background: $theme.colors.bgWhite,
    padding: $theme.sizes.scale400,
    minWidth: '19rem'
  })
);

const PopoverFooter = themedStyled('div', () => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const DeviceFilterTextArea = ({
  label,
  value,
  onChange,
  placeholder
}: TextareaType): JSX.Element => (
  <FormControl label={label} key={label}>
    <Textarea
      id={`textarea-${label}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </FormControl>
);

export const DeviceFilterCheckboxGroup = ({
  label,
  options,
  value,
  onChange
}: CheckboxType): JSX.Element => {
  const noValue = !value || value.length === 0;

  return (
    <FormControl label={label} key={label}>
      <>
        <Checkbox
          key='all'
          checked={noValue}
          isIndeterminate={!noValue}
          onChange={(event): void => onChange(event)}
        >
          All
        </Checkbox>
        {options.map((option) => (
          <Checkbox
            key={option.name}
            value={option.value}
            checked={option.value !== undefined && value?.includes(option.value)}
            onChange={(event): void => onChange(event)}
          >
            {option.name}
          </Checkbox>
        ))}
      </>
    </FormControl>
  );
};

export const DeviceFilter = ({
  resetAllFilters,
  applyFilters,
  children
}: DeviceFilterProps): JSX.Element | null => {
  const [, theme] = useStyletron();
  const [isFilterOpen, setIsOpenFilter] = useState(false);

  const applyFilter = (close: () => void): void => {
    close();
    setIsOpenFilter(!isFilterOpen);
    applyFilters();
  };

  return (
    <StatefulPopover
      initialState={{ isOpen: isFilterOpen }}
      placement={PLACEMENT.leftTop}
      content={({ close }): JSX.Element => (
        <PopoverContainer>
          <div
            style={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto'
            }}
          >
            {children}
          </div>
          <PopoverFooter>
            <Button
              variant='secondary'
              onClick={resetAllFilters}
              style={{ marginRight: theme.sizing.scale600 }}
            >
              Reset All
            </Button>
            <Button variant='primary' onClick={(): void => applyFilter(close)}>
              Apply
            </Button>
          </PopoverFooter>
        </PopoverContainer>
      )}
    >
      <Button
        variant='primary'
        onClick={applyFilters}
        icon={(): JSX.Element => (
          <FilterIcon fill='white' width={theme.sizing.scale600} height={theme.sizing.scale600} />
        )}
      >
        Filter Devices
      </Button>
    </StatefulPopover>
  );
};
