import { toaster } from 'baseui/toast';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleObject } from 'styletron-react';

import { DeviceType, DeviceCommandInstruction } from '@wunder/tools-iot-connector-device-types';

import { useStores } from '../../hooks/use-stores';
import { DeviceDocument } from '../../models';
import { FilterTypes } from '../../stores/deviceStore';
import { themedStyled } from '../../theme/theme';
import { sendCommand } from '../../utils/fleetApi';
import { AddNewDevices } from '../addNewDevices/AddNewDevices';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import { DeviceListTableFilter } from '../deviceListTable/DeviceListTableFilter';
import { FirmwareModal } from '../firmware/FirmwareModal';

import { DeviceListBatchAction, DeviceListBatchActionId } from './DeviceListActions';
import { DeviceListBar } from './DeviceListBar';
import { DeviceListBarFilter } from './DeviceListBarFilter';
import { ActiveFilterValueMap, DeviceListBarFilterTags } from './DeviceListBarFilterTags';
import { DeviceListCopyIMEI } from './DeviceListCopyIMEI';
import { DeviceListHeaderAlerts } from './DeviceListHeaderAlerts';

const MainContainer = themedStyled('div', ({ $theme }) => ({
  alignItems: 'flex-start',
  height: 'auto',
  marginBottom: $theme.sizes.scale300,
  marginTop: $theme.sizes.scale400,
}));

const BodyText = themedStyled(
  'p',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.contentBodyRegular,
    marginBottom: $theme.sizes.scale400,
    marginTop: $theme.sizes.scale0,
  }),
);

const BoldBodyText = themedStyled(
  'strong',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.contentBodyBold,
  }),
);

const pluralize = (word: string, occurrences: number) => {
  if (occurrences > 1) {
    return `${word}s`;
  }
  return word;
};

interface ConfirmationModalContentProps {
  selected: string[];
}

interface AvailableType {
  id: string;
  name: string;
  value: string;
}

const ConfirmationModalContent = ({ selected }: ConfirmationModalContentProps) => {
  return (
    <BodyText>
      {`Do you want to update the
        ${pluralize('device', selected.length || 0)} ${pluralize(
        'password',
        selected.length || 0,
      )} to the`}{' '}
      <BoldBodyText>Tenant&apos;s password</BoldBodyText>?
      <br />
      Please type the <BoldBodyText>Manufacturer&apos;s Device Password</BoldBodyText> to confirm.
    </BodyText>
  );
};

interface RemoveDevicesConfirmationModalProps {
  devicesCount: number;
  isOpen: boolean;
  onConfirmed: () => void;
  setOpen: (open: boolean) => void;
}

const RemoveDevicesConfirmationModal: React.FC<RemoveDevicesConfirmationModalProps> = ({
  devicesCount,
  isOpen,
  onConfirmed,
  setOpen,
}: RemoveDevicesConfirmationModalProps) => (
  <ConfirmationModal
    isOpen={isOpen}
    validateInput={(inputText) => inputText === 'REMOVE'}
    title="Really remove devices from your list?"
    placeholder="Type 'REMOVE'"
    primaryButtonText="REMOVE"
    primaryAction={async (): Promise<void> => {
      onConfirmed();
      setOpen(false);
    }}
    secondaryButtonText="Abort"
    onClose={() => setOpen(!isOpen)}
  >
    <BodyText>
      Are you sure you want to remove {devicesCount} devices from your list? (*Active devices
      reappear automatically) Please type <BoldBodyText>REMOVE</BoldBodyText> to confirm.
      <br />
    </BodyText>
  </ConfirmationModal>
);

interface DeviceListHeaderProps {
  selected: string[] | undefined;
  onClearSelection: () => void;
}

export const DeviceListHeader = ({
  selected,
  onClearSelection,
}: DeviceListHeaderProps): JSX.Element => {
  const { deviceStore, tenantStore, deviceConfigurationStore, userStore } = useStores();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = React.useState(false);
  const [isFirmwareModalOpen, setIsFirmwareModalOpen] = React.useState(false);
  const [isFirmwareConfirmationModalOpen, setIsFirmwareConfirmationModalOpen] =
    React.useState(false);
  const [isRemoveDevicesModalOpen, setRemoveDevicesModalOpen] = React.useState(false);

  const [selectedDevices, setSelectedDevices] = React.useState<DeviceDocument[]>([]);
  const [firmwarePayload, setFirmwarePayload] = React.useState('');

  const { t } = useTranslation();

  React.useEffect(() => {
    if (selected) {
      setSelectedDevices(deviceStore.getDevices(selected) || []);
    }
  }, [selected]);

  const { currentUser } = userStore;

  const handleCommand = async (
    deviceId: string,
    command: DeviceCommandInstruction,
    payload?: Record<string, unknown>,
  ): Promise<void> => {
    if (!tenantStore.currentTenant?.iotConnector.baseUrl) {
      toaster.negative(<>{t('tenant.toast.missingBaseUrl')}</>, {});
    }

    if (tenantStore.currentTenant && currentUser?.hasWriteAccessOnSelectedTenant()) {
      const response = await sendCommand(deviceId, tenantStore.currentTenant, command, payload);
      if (!response.success) {
        toaster.negative(<>{response.error}</>, {});
      }
    }
  };

  const onActionClick = (filterType: FilterTypes, filterValues: string[]) => {
    deviceStore.onActionTagClick(filterType, filterValues);
  };

  const availableConfigurations =
    deviceConfigurationStore.deviceConfigurations?.map((config) => ({
      name: config.name,
      value: config.id,
    })) || [];

  const availableTypes: AvailableType[] = deviceStore.uniqueDeviceTypes.map(
    (deviceType: DeviceType) => ({
      id: deviceType?.id,
      name: deviceType?.name,
      value: deviceType?.id,
    }),
  );

  const availableFirmwareVersions =
    deviceStore.uniqueFirmwareVersions.map((firmware) => ({
      name: firmware,
      value: firmware,
    })) || [];

  const activeDeviceIdFilterValues = deviceStore.activeFilters.deviceIds.map((deviceId) => ({
    name: deviceId,
    value: deviceId,
  }));

  const activeConfigurationFilterValues = deviceStore.activeFilters.configurations.map(
    (configurationId) => ({
      name: configurationId,
      value: deviceConfigurationStore.getDeviceConfigurationName(configurationId) || '',
    }),
  );

  const activeTypeFilterValues = deviceStore.activeFilters.types.map((typeId) => ({
    name: typeId,
    value:
      availableTypes.find((availableType: AvailableType) => availableType.id === typeId)?.name ||
      '',
  }));

  const activeFirmwareVersionFilterValues = deviceStore.activeFilters.firmwareVersions.map(
    (version) => ({
      name: version,
      value: version,
    }),
  );

  const handleBatchSendCommands = (
    devices: string[],
    command: DeviceCommandInstruction,
    payload?: Record<string, unknown>,
  ): void => {
    devices.forEach((deviceId) => {
      handleCommand(deviceId, command, payload);
    });
  };

  const activeFilterValueMap: ActiveFilterValueMap = {
    deviceConfigurations: activeConfigurationFilterValues,
    deviceIds: activeDeviceIdFilterValues,
    deviceTypes: activeTypeFilterValues,
    firmewareVersions: activeFirmwareVersionFilterValues,
  };

  return (
    <MainContainer>
      <DeviceListHeaderAlerts />

      {selected && !!currentUser?.hasWriteAccessOnSelectedTenant() ? (
        <DeviceListBar
          selected={selected}
          selectedDevices={selectedDevices}
          handleBatchSendCommands={handleBatchSendCommands}
          openModal={(instruction: DeviceListBatchActionId) => {
            if (instruction === DeviceCommandInstruction.firmwareUpdate) {
              setIsFirmwareModalOpen(true);
            }
            if (instruction === DeviceCommandInstruction.changePassword) {
              setIsChangePasswordModalOpen(true);
            }
            if (instruction === DeviceListBatchAction.remove) {
              setRemoveDevicesModalOpen(true);
            }
          }}
        />
      ) : (
        <DeviceListBarFilter headline="Device List">
          <DeviceListBarFilterTags
            activeFilterValueMap={activeFilterValueMap}
            onActionClick={onActionClick}
          />

          <DeviceListCopyIMEI variant="text" devices={deviceStore.devicesToRender || []} />

          <AddNewDevices />

          <DeviceListTableFilter
            activeFilter={activeFilterValueMap}
            availableConfigurations={availableConfigurations}
            availableFirwareVersions={availableFirmwareVersions}
            availableTypes={availableTypes}
            onApply={(newFilter) => deviceStore.replaceFilters(newFilter)}
            onReset={() => deviceStore.removeAllFilter()}
          />
        </DeviceListBarFilter>
      )}

      <ConfirmationModal
        isOpen={isChangePasswordModalOpen}
        validateInput={(inputText) => inputText.length > 0}
        title={`Change Device ${pluralize('Password', selected?.length || 0)}?`}
        primaryButtonText={`Change ${pluralize('Password', selected?.length || 0)}`}
        placeholder="Current Manufacturer's Device Password"
        primaryAction={(_, oldPassword: string) => {
          const newPassword = tenantStore.currentTenant?.devicePassword;

          if (newPassword && selected) {
            handleBatchSendCommands(selected, DeviceCommandInstruction.changePassword, {
              oldPassword,
            });
          }
          setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
        }}
        secondaryButtonText="Abort"
        onClose={() => setIsChangePasswordModalOpen(!isChangePasswordModalOpen)}
      >
        <ConfirmationModalContent selected={selected || []} />
      </ConfirmationModal>

      <FirmwareModal
        firmwarePayload={firmwarePayload}
        isOpen={isFirmwareModalOpen}
        onChange={(payload: string) => setFirmwarePayload(payload)}
        onClose={() => setIsFirmwareModalOpen(!isFirmwareModalOpen)}
        onSubmit={() => {
          setIsFirmwareModalOpen(!isFirmwareModalOpen);
          setIsFirmwareConfirmationModalOpen(true);
        }}
      />

      <ConfirmationModal
        isOpen={isFirmwareConfirmationModalOpen}
        validateInput={(inputText) => inputText === 'Update Firmware'}
        title="Update Firmware?"
        placeholder="Type 'Update Firmware'"
        primaryButtonText="Update Firmware"
        primaryAction={async (): Promise<void> => {
          try {
            const formattedPayload = JSON.parse(firmwarePayload);

            handleBatchSendCommands(
              selected || [],
              DeviceCommandInstruction.firmwareUpdate,
              formattedPayload,
            );
          } catch (e) {
            toaster.negative(<>{t('tenant.toast.errorParsingPayload')}</>, {});
          }
          setIsFirmwareConfirmationModalOpen(false);
        }}
        secondaryButtonText="Abort"
        onClose={() => setIsFirmwareConfirmationModalOpen(!isFirmwareConfirmationModalOpen)}
      >
        <BodyText>
          Please type <BoldBodyText>Update Firmware</BoldBodyText> to confirm.
          <br />
        </BodyText>
      </ConfirmationModal>

      <RemoveDevicesConfirmationModal
        devicesCount={selectedDevices.length}
        isOpen={isRemoveDevicesModalOpen}
        setOpen={(open) => setRemoveDevicesModalOpen(open)}
        onConfirmed={async () => {
          await deviceStore.removeDevices(selectedDevices);
          onClearSelection();
        }}
      />
    </MainContainer>
  );
};
