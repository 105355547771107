import { toaster } from 'baseui/toast';
import React from 'react';

const AUTO_HIDE_DURATION_IN_MS = 4000;

export interface CopyToClipboardPros {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}

export function copyToClipboard(props: CopyToClipboardPros): void {
  const {
    errorMessage = 'Error copying text',
    successMessage = 'Text copied successfully',
    text,
  } = props;

  navigator.clipboard
    .writeText(text)
    .then(() => {
      toaster.positive(<>{successMessage}</>, {
        autoHideDuration: AUTO_HIDE_DURATION_IN_MS,
      });
    })
    .catch(() => {
      toaster.negative(<>{errorMessage}</>, {
        autoHideDuration: AUTO_HIDE_DURATION_IN_MS,
      });
    });
}
