import { Input } from 'baseui/input';
import React from 'react';

import { FormFieldNumberOptions } from './formFieldTypes';

export const CustomNumberInput = ({
  value,
  name,
  endEnhancerText,
  onChange,
  inputProps,
  disabled,
}: {
  value: number;
  name: string;
  endEnhancerText?: string;
  onChange: (e: React.SyntheticEvent) => void;
  inputProps?: FormFieldNumberOptions;
  disabled?: boolean;
}): JSX.Element => (
  <Input
    type="number"
    name={name}
    value={value}
    onChange={onChange}
    min={inputProps ? inputProps.min : 1}
    max={inputProps ? inputProps.max : 600}
    endEnhancer={endEnhancerText}
    disabled={disabled}
  />
);
