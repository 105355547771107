import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { wunderTheme } from '../../theme/theme';
import { Tab, Tabs } from '../tabs/Tabs';

import { FormikValues } from 'formik';
import {
  CloseHandler,
  DialogFormBase,
  DialogFormChildrenProps,
  generateFieldNames,
  SubmitHandler
} from './DialogFormBase';
import { TabbedFormField } from './formFieldTypes';
import { renderFields } from './renderFields';

interface DialogTabbedFormProps<V> {
  fields: TabbedFormField[];
  initialValues: V;
  loading: boolean;
  onClose: CloseHandler;
  onSubmit: SubmitHandler<V>;
  open: boolean;
  title: string;
  readonly?: boolean;
  size?: 'default' | 'large';
}

export function DialogTabbedForm<V extends FormikValues>({
  fields,
  initialValues,
  loading,
  onClose,
  onSubmit,
  open,
  title,
  readonly,
  size = 'default'
}: DialogTabbedFormProps<V>): JSX.Element {
  const { tabLabel } = fields[0] as TabbedFormField;
  const [activeKey, setActiveKey] = useState<string | number>(tabLabel);
  const tabbedField = (fields as TabbedFormField[]).filter((field) => field.tabLabel === activeKey);

  return (
    <DialogFormBase
      initialValues={initialValues}
      loading={loading}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      title={title}
      readonly={readonly}
      size={size}
    >
      {(props: DialogFormChildrenProps<V>) => (
        <>
          <Tabs
            activeKey={activeKey}
            style={{ backgroundColor: wunderTheme.colors.bgWhite }}
            onChange={({ activeKey: active }): void => {
              setActiveKey(active);
            }}
          >
            {(fields as TabbedFormField[]).map((field: TabbedFormField) => {
              return (
                <Tab key={field.tabLabel} title={field.tabLabel}>
                  {renderFields(generateFieldNames(tabbedField[0].formFields), {
                    ...props,
                    loading
                  })}
                </Tab>
              );
            })}
          </Tabs>
          {activeKey === 'General' && (
            <Link
              to='/docs'
              onClick={onClose}
              style={{
                color: wunderTheme.colors.primary100
              }}
            >
              API Documentation
            </Link>
          )}
        </>
      )}
    </DialogFormBase>
  );
}
