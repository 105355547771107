import React from 'react';

import { authApiClient, iotApiClient } from '../client/api';
import {
  DeviceConfigurationStore,
  DeviceLogStore,
  DeviceStore,
  FeatureStore,
  TenantStore,
  UiStore,
  UserStore,
} from '../stores';
import { config } from '../utils/config';

const uiStore = new UiStore();
const deviceConfigurationStore = new DeviceConfigurationStore(iotApiClient);
const deviceStore = new DeviceStore(iotApiClient, deviceConfigurationStore);
const userStore = new UserStore(authApiClient);
const tenantStore = new TenantStore(userStore, iotApiClient);
const featureStore = new FeatureStore(iotApiClient, config.features);
const deviceLogStore = new DeviceLogStore(iotApiClient);

export const storesContext = React.createContext({
  deviceConfigurationStore,
  deviceLogStore,
  deviceStore,
  featureStore,
  tenantStore,
  uiStore,
  userStore,
});
