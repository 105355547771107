import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { SelectDropdown } from 'baseui/select';
import React from 'react';
import { StyleObject, StyletronComponent } from 'styletron-react';

import { DeviceCommandInstruction } from '@wunder/tools-iot-connector-device-types';

import { WunderTheme, themedStyled, themedUseStyletron as useStyletron } from '../../theme/theme';
import { Button } from '../button/Button';

export type DeviceBatchButtonListOption = {
  id: DeviceCommandInstruction;
  label: string;
};

export type OnItemSelect = (args: {
  item: DeviceBatchButtonListOption;
  event?: React.SyntheticEvent<HTMLElement> | KeyboardEvent;
}) => void;

interface BatchButtonProps {
  label: string;
  icon?: React.ComponentType<unknown>;
  onClick?: () => void;
  testId?: string;
  last?: boolean;
}

export const DeviceBatchButton = React.forwardRef(
  ({ label, icon, last, onClick, testId }: BatchButtonProps, ref): JSX.Element => {
    const [, theme] = useStyletron();

    return (
      <Button
        key={label}
        ref={ref}
        onClick={onClick}
        icon={icon}
        variant='primary'
        style={{
          marginBottom: 0,
          marginLeft: theme.sizing.scale200,
          marginRight: last ? 0 : theme.sizing.scale200,
          marginTop: 0
        }}
        overrides={{
          BaseButton: {
            props: testId
              ? {
                  'data-test-id': testId
                }
              : {}
          }
        }}
      >
        {label}
      </Button>
    );
  }
);

interface BatchButtonListProps extends BatchButtonProps {
  onItemSelect?: OnItemSelect;
  items: Array<{ id: string; name: string; value?: string }>;
  isOptionDisabled?: (id: string, value?: string) => boolean;
}

export const DeviceBatchButtonList = ({
  label,
  icon,
  last,
  testId,
  onClick,
  onItemSelect,
  items,
  isOptionDisabled
}: BatchButtonListProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <StatefulPopover
      initialState={{ isOpen }}
      placement={PLACEMENT.bottomRight}
      content={({ close }): JSX.Element => (
        <SelectDropdown
          value={[]}
          valueKey='id'
          labelKey='label'
          getOptionLabel={({ option }): React.ReactNode => {
            return <span>{option.label}</span>;
          }}
          options={items.map((item) => ({
            disabled: isOptionDisabled ? isOptionDisabled(item.id, item.value) : undefined,
            id: item.id,
            label: item.name
          }))}
          onItemSelect={(args): void => {
            if (onItemSelect) {
              onItemSelect(args);
            }
            close();
          }}
          overrides={{
            DropdownContainer: {
              style: (): StyleObject => ({
                width: 'auto'
              })
            }
          }}
        />
      )}
    >
      <DeviceBatchButton
        label={label}
        icon={icon}
        last={last}
        testId={testId}
        onClick={(): void => {
          if (onClick) {
            onClick();
          }
          setIsOpen(true);
        }}
      />
    </StatefulPopover>
  );
};

export const DeviceListBatchActionContainer: StyletronComponent<any> = themedStyled(
  'div',
  ({ $theme }: { $theme: WunderTheme }) => ({
    alignItems: 'center',
    backgroundColor: $theme.colors.primary100,
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-end'
  })
);
