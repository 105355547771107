import { withStyle } from 'baseui';
import { StyledSpinnerNext } from 'baseui/spinner';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { SYNC_STATUS_TOTAL_DEVICES_NUMBER } from '../../e2e/testIds';
import { themedStyled, themedUseStyletron as useStyletron, WunderTheme } from '../../theme/theme';

interface SyncStatusTileProps {
  headlineText: string;
  subHeadlineText: string;
  accentColor?: string;
  isLoading?: boolean;
  isSelected?: boolean;
  onClick: (headlineText: string) => void;
}

type TileProps = {
  $theme: WunderTheme;
  $isSelected?: boolean;
};

const Tile = themedStyled('button', ({ $theme, $isSelected }: TileProps) => ({
  border: 'none',
  width: '11.875rem', // each Tile should have the exact same dimensions
  paddingLeft: $theme.sizes.scale0,
  paddingRight: $theme.sizes.scale0,
  marginRight: $theme.sizes.scale500,
  marginTop: $theme.sizes.scale300,
  marginBottom: $theme.sizes.scale300,
  backgroundColor: $isSelected ? $theme.colors.bgWhite : $theme.colors.bg10,
  boxShadow: $isSelected ? $theme.shadows.elevation_4 : 'none',

  ':hover': {
    backgroundColor: $theme.colors.bgWhite,
    boxShadow: $theme.shadows.elevation_4,
    cursor: 'pointer',
  },

  ':focus': {
    backgroundColor: $theme.colors.bgWhite,
    boxShadow: $theme.shadows.elevation_4,
    outline: 'none',
  },
}));

type TileHeadlineProps = {
  $theme: WunderTheme;
  $accentColor?: string;
};

const TileSubHeadline = themedStyled(
  'p',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.labelCaptionRegular,
    color: $theme.colors.text100,
    textAlign: 'left',
    marginTop: $theme.sizes.scale0,
    marginBottom: $theme.sizes.scale300,
    marginLeft: $theme.sizes.scale400,
  }),
);

const TileHeadline = themedStyled('p', ({ $accentColor, $theme }: TileHeadlineProps) => ({
  ...$theme.typography.headingLargeRegular,
  color: $accentColor || $theme.colors.text100,
  textAlign: 'left',
  marginTop: $theme.sizes.scale200,
  marginBottom: $theme.sizes.scale100,
  marginLeft: $theme.sizes.scale400,
}));

const TileFooter = themedStyled(
  'div',
  (): StyleObject => ({
    display: 'flex',
    justifyContent: 'space-between',
  }),
);

export const SyncStatusTile = ({
  headlineText,
  subHeadlineText,
  accentColor,
  isLoading,
  isSelected,
  onClick,
}: SyncStatusTileProps): JSX.Element => {
  const [, theme] = useStyletron();

  const Spinner = withStyle(StyledSpinnerNext, {
    borderTopColor: theme.colors.text100,
    width: theme.sizes.scale300,
    height: theme.sizes.scale300,
    marginRight: theme.sizes.scale300,
    borderLeftWidth: '2px', // edge-case
    borderRightWidth: '2px', // edge-case
    borderTopWidth: '2px', // edge-case
    borderBottomWidth: '2px', // edge-case
    cursor: 'pointer',
  });

  return (
    <Tile
      $theme={theme}
      onClick={(): void => onClick(headlineText)}
      type="button"
      $isSelected={isSelected}
    >
      <TileHeadline
        $theme={theme}
        $accentColor={accentColor}
        data-test-id={SYNC_STATUS_TOTAL_DEVICES_NUMBER}
      >
        {headlineText}
      </TileHeadline>
      <TileFooter>
        <TileSubHeadline>{subHeadlineText}</TileSubHeadline>
        {isLoading && <Spinner />}
      </TileFooter>
    </Tile>
  );
};
