import { Button as BaseWebButton, ButtonProps as BaseWebButtonProps } from 'baseui/button';
import { merge } from 'lodash';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron, themedStyled } from '../../theme/theme';
import { useHover } from '../../utils/useHover';

import { ButtonIcon } from './ButtonIcon';
import {
  getBackgroundColor,
  getColor,
  getColorHoverEnabled,
  getBackgroundHoverEnabled,
  getBackgroundColorDisabled,
  ButtonVariant,
} from './buttonStyles';

interface ButtonProps extends BaseWebButtonProps {
  icon?: React.ComponentType<unknown>;
  variant: ButtonVariant;
  ref?: React.Ref<unknown>;
  style?: StyleObject;
}

type TypographyStylesType = {
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
};

type ButtonSizeType = 'compact' | 'default' | 'large' | 'mini' | undefined;

const getButtonTypography = (size: ButtonSizeType): TypographyStylesType => {
  const [, theme] = useStyletron();
  if (size === 'large') {
    return { ...theme.typography.contentBodyBold };
  }
  return { ...theme.typography.labelCaptionRegular };
};

export const Button = React.forwardRef(
  (
    { icon, children, variant, style, size, overrides, ...others }: ButtonProps,
    ref,
  ): JSX.Element => {
    const [, theme] = useStyletron();
    const [hoverRef, hovered] = useHover();
    const localRef = ref || hoverRef;

    const sharedButtonProperties = {
      ...getButtonTypography(size),
      paddingTop: size === 'large' ? theme.sizes.scale300 : theme.sizes.scale200,
      paddingBottom: size === 'large' ? theme.sizes.scale300 : theme.sizes.scale200,
      paddingLeft: size === 'large' ? theme.sizes.scale400 : theme.sizes.scale200,
      paddingRight: size === 'large' ? theme.sizes.scale400 : theme.sizes.scale200,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    };

    const ButtonText = themedStyled('span', () => ({
      top: '0.125rem', // scale100 / 2
      position: 'relative',
    }));

    const mergedOverrides = merge(overrides, {
      BaseButton: {
        style: (): StyleObject => {
          return {
            ...sharedButtonProperties,
            color: getColor(variant),
            backgroundColor: getBackgroundColor(variant),

            ':hover:enabled': {
              color: getColorHoverEnabled(variant),
              backgroundColor: getBackgroundHoverEnabled(variant),
            },

            ':disabled': {
              color: theme.colors.text50,
              backgroundColor: getBackgroundColorDisabled(variant),
            },

            // must be last to override the defaults!
            ...style,
          };
        },
      },
    });

    return (
      <BaseWebButton
        ref={localRef}
        endEnhancer={
          icon !== undefined ? (
            <ButtonIcon
              icon={icon}
              variant={variant}
              disabled={!!others.disabled}
              hovered={hovered}
            />
          ) : undefined
        }
        overrides={mergedOverrides}
        {...others}
      >
        <ButtonText>{children}</ButtonText>
      </BaseWebButton>
    );
  },
);
