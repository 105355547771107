import { overrides } from '../../theme/theme';

export type ButtonVariant = 'primary' | 'secondary' | 'text' | 'toolbar';

export const getColor = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.textWhite;

    case 'secondary':
      return overrides.colors.primary100;

    case 'text':
      return overrides.colors.primary100;

    case 'toolbar':
      return overrides.colors.textWhite;

    default:
      return overrides.colors.textWhite;
  }
};

export const getFillDisabled = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.bg50;

    case 'secondary':
      return overrides.colors.bg50;

    case 'text':
      return overrides.colors.primary200;

    case 'toolbar':
      return overrides.colors.bg50;

    default:
      return overrides.colors.bg50;
  }
};

export const getBackgroundColor = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.primary100;

    case 'secondary':
      return overrides.colors.primary10;

    case 'text':
      return 'transparent';

    case 'toolbar':
      return overrides.colors.primary100;

    default:
      return overrides.colors.primary100;
  }
};

export const getColorHoverEnabled = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.textWhite;

    case 'secondary':
      return overrides.colors.primary200;

    case 'text':
      return overrides.colors.primary100;

    case 'toolbar':
      return overrides.colors.primary100;

    default:
      return overrides.colors.textWhite;
  }
};

export const getBackgroundHoverEnabled = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.primary200;

    case 'secondary':
      return overrides.colors.primary10;

    case 'text':
      return 'transparent';

    case 'toolbar':
      return overrides.colors.bgWhite;

    default:
      return overrides.colors.primary200;
  }
};

export const getBackgroundColorDisabled = (kind: ButtonVariant): string => {
  switch (kind) {
    case 'primary':
      return overrides.colors.bg25;

    case 'secondary':
      return overrides.colors.bg25;

    case 'text':
      return 'transparent';

    case 'toolbar':
      return overrides.colors.bg25;

    default:
      return overrides.colors.bg25;
  }
};
