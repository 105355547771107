import { FormikValues } from 'formik';

import { FormField, TabbedFormField } from '../formHelper/formFieldTypes';

import { deviceTypeConfigurationFields } from './deviceTypeConfigurationFields';

const ioTSettingsFields: FormField[] = [
  {
    name: 'iot.accelerometerSensitivity',
    options: [
      { name: 'Low', value: 1 },
      { name: 'Middle (Default)', value: 2 },
      { name: 'High', value: 3 },
    ],
    translationKey: 'deviceConfiguration.iot.accelerometerSensitivity',
    type: 'select',
  },
  {
    name: 'iot.heartbeatUploadInterval',
    translationKey: 'deviceConfiguration.iot.heartbeatUploadInterval',
    type: 'number',
    unit: 'sec',
  },
  {
    name: 'iot.reportDeviceStatusWhenUnlocked',
    translationKey: 'deviceConfiguration.iot.reportDeviceStatusWhenUnlocked',
    type: 'switch',
  },
  {
    name: 'iot.deviceStatusWhenUnlockedInterval',
    translationKey: 'deviceConfiguration.iot.deviceStatusWhenUnlockedInterval',
    type: 'number',
    unit: 'sec',
  },
  {
    name: 'iot.positionUploadInterval',
    translationKey: 'deviceConfiguration.iot.positionUploadInterval',
    type: 'number',
    unit: 'sec',
  },
];

const deviceSettingsFields: FormField[] = [
  {
    name: 'settings.inchSpeedDisplay',
    translationKey: 'deviceConfiguration.settings.inchSpeedDisplay',
    type: 'switch',
  },
  {
    name: 'settings.cruiseControlSetting',
    translationKey: 'deviceConfiguration.settings.cruiseControlSetting',
    type: 'switch',
  },
  {
    name: 'settings.startupModeSetting',
    translationKey: 'deviceConfiguration.settings.startupModeSetting',
    type: 'switch',
  },
  {
    name: 'settings.buttonSwitchingSpeedMode',
    translationKey: 'deviceConfiguration.settings.buttonSwitchingSpeedMode',
    type: 'switch',
  },
  {
    name: 'settings.keySwitchHeadlight',
    translationKey: 'deviceConfiguration.settings.keySwitchHeadlight',
    type: 'switch',
  },
  {
    name: 'settings.lowSpeedModeSpeedLimit',
    options: { max: 25, min: 6, step: 1 }, // Omni IoT only allows a range from 6 - 25 kph
    translationKey: 'deviceConfiguration.settings.lowSpeedModeSpeedLimit',
    type: 'number',
    unit: 'km/h',
  },
  {
    name: 'settings.mediumSpeedModeSpeedLimit',
    options: { max: 25, min: 6, step: 1 }, // Omni IoT only allows a range from 6 - 25 kph
    translationKey: 'deviceConfiguration.settings.mediumSpeedModeSpeedLimit',
    type: 'number',
    unit: 'km/h',
  },
  {
    name: 'settings.highSpeedModeSpeedLimit',
    options: { max: 25, min: 6, step: 1 }, // Omni IoT only allows a range from 6 - 25 kph
    translationKey: 'deviceConfiguration.settings.highSpeedModeSpeedLimit',
    type: 'number',
    unit: 'km/h',
  },
  {
    name: 'settings.alarmActive',
    translationKey: 'deviceConfiguration.settings.alarmActive',
    type: 'switch',
  },
  {
    disabled: (values: FormikValues): boolean => !values.settings.alarmActive,
    name: 'settings.alarmVolume',
    options: { max: 255, min: 0, step: 1 },
    translationKey: 'deviceConfiguration.settings.alarmVolume',
    type: 'number',
  },
  {
    disabled: (values: FormikValues): boolean => !values.settings.alarmActive,
    name: 'settings.alarmSensitivity',
    options: { max: 255, min: 0, step: 1 },
    translationKey: 'deviceConfiguration.settings.alarmSensitivity',
    type: 'number',
  },
];

const deviceRideSettingsFields: FormField[] = [
  {
    name: 'rideSettings.headlightSwitch',
    options: [
      {
        name: 'Default',
        value: 0,
      },
      {
        name: 'Shut down',
        value: 1,
      },
      {
        name: 'Open',
        value: 2,
      },
    ],
    translationKey: 'deviceConfiguration.rideSettings.headlightSwitch',
    type: 'select',
  },
  {
    label: 'Throttle response',
    name: 'rideSettings.throttleResponse',
    options: [
      {
        name: 'Default',
        value: 0,
      },
      {
        name: 'Shut down',
        value: 1,
      },
      {
        name: 'Open',
        value: 2,
      },
    ],
    translationKey: 'deviceConfiguration.rideSettings.throttleResponse',
    type: 'select',
  },
  {
    name: 'rideSettings.taillightsFlashing',
    options: [
      {
        name: 'Default',
        value: 0,
      },
      {
        name: 'Shut down',
        value: 1,
      },
      {
        name: 'Open',
        value: 2,
      },
    ],
    translationKey: 'deviceConfiguration.rideSettings.taillightsFlashing',
    type: 'select',
  },
  {
    label: 'Speed mode setting',
    name: 'rideSettings.modeSetting',
    options: [
      {
        name: 'Low speed',
        value: 1,
      },
      {
        name: 'Middle speed (Default)',
        value: 2,
      },
      {
        name: 'High speed',
        value: 3,
      },
    ],
    translationKey: 'deviceConfiguration.rideSettings.modeSetting',
    type: 'select',
  },
];

export function getDeviceConfigurationFields(deviceTypeId?: string): TabbedFormField[] {
  let ioTSettings = ioTSettingsFields;
  let deviceSettings = deviceSettingsFields;
  let deviceRideSettings = deviceRideSettingsFields;

  const fields = deviceTypeConfigurationFields.find((field) => field.deviceTypeId === deviceTypeId);

  if (fields) {
    ioTSettings = ioTSettings.map(
      (field) => fields.iotSettingsFields?.find((f) => f.name === field.name) || field,
    );
    deviceSettings = deviceSettings.map(
      (field) => fields.deviceSettingsFields?.find((f) => f.name === field.name) || field,
    );
    deviceRideSettings = deviceRideSettings.map(
      (field) => fields.rideSettingsFields?.find((f) => f.name === field.name) || field,
    );
  }

  return [
    { formFields: ioTSettings, tabLabel: 'IoT Settings' },
    { formFields: deviceSettings, tabLabel: 'Device Settings' },
    { formFields: deviceRideSettings, tabLabel: 'Device Ride Settings' },
  ];
}
