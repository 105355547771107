// Taken from Wunder's Design System
// https://www.figma.com/file/S3BjBDFEVuPJQg17ap9Wef/Local-Design-System-%E2%9A%99%EF%B8%8F?node-id=61%3A88
// Use for all typography's within our components
// We are using the 'rem' unit in order to guarantee that the app scales proportionally to the users configured default font-size, to provide a good accessibility.

const fontFamilyAvertaPeBold = 'AvertaPE-Bold';
const fontFamilyAvertaPeRegular = 'AvertaPE-Regular';
const fontFamilyAvertaPeSemibold = 'AvertaPE-Semibold';

export const themeTypography = {
  headingLargeBold: {
    fontFamily: fontFamilyAvertaPeBold,
    fontSize: '1.5rem', // 24px
    lineHeight: '2.5rem', // 40px
  },
  headingLargeRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '1.5rem', // 24px
    lineHeight: '2.25rem', // 36px
  },
  headingLargeSemiBold: {
    fontFamily: fontFamilyAvertaPeSemibold,
    fontSize: '1.5rem', // 24px
    lineHeight: '2.25rem', // 36px
  },
  headingMediumBold: {
    fontFamily: fontFamilyAvertaPeBold,
    fontSize: '1.25rem', // 20px
    lineHeight: '2rem', // 32px
  },
  headingMediumRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '1.25rem', // 20px
    lineHeight: '2rem', // 32px
  },
  headingSmallBold: {
    fontFamily: fontFamilyAvertaPeBold,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
  },
  headingSmallRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
  },

  contentBodyBold: {
    fontFamily: fontFamilyAvertaPeSemibold,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.5rem', // 24px
  },
  contentBodyRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.5rem', // 24px
  },
  contentBodyNumbers: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.5rem', // 24px
  },
  contentBodyLink: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.5rem', // 24px
  },

  labelCaptionBold: {
    fontFamily: fontFamilyAvertaPeBold,
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  },
  labelCaptionRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  },
  labelSmallBold: {
    fontFamily: fontFamilyAvertaPeBold,
    fontSize: '0.625rem', // 10px
    lineHeight: '0.75rem', // 12px
  },
  labelSmallRegular: {
    fontFamily: fontFamilyAvertaPeRegular,
    fontSize: '0.625rem', // 10px
    lineHeight: '0.75rem', // 12px
  },
};
