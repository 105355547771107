import { subMinutes } from 'date-fns';
import { action, computed, observable } from 'mobx';

import { IOTClient } from '../client/interfaces';

export interface DeviceLogType {
  message: string;
  type: 0 | 1;
  timestamp: string;
}

export enum LoadingType {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface DeviceFetchOptions {
  tenantId: string;
  deviceId: string;
  dates: Date[];
  page?: number;
  limit?: number;
}

export class DeviceLogStore {
  @observable data: DeviceLogType[] | null = null;

  @observable loadingState: LoadingType = LoadingType.INITIAL;

  constructor(readonly iotClient: IOTClient) {}

  @action
  async fetchDeviceLog({
    tenantId,
    deviceId,
    dates,
    limit,
    page,
  }: DeviceFetchOptions): Promise<void> {
    this.data = null;
    this.loadingState = LoadingType.LOADING;

    const startDate = subMinutes(dates[0], dates[0].getTimezoneOffset()).toISOString();
    const endDate = subMinutes(dates[1], dates[1].getTimezoneOffset()).toISOString();
    const response = await this.iotClient.fetchDeviceLogs(
      tenantId,
      deviceId,
      startDate,
      endDate,
      page,
      limit,
    );

    if (response.success && response.logs) {
      this.data = response.logs;
      this.loadingState = LoadingType.SUCCESS;
    } else {
      this.loadingState = LoadingType.ERROR;
    }
  }

  @action
  resetData(): void {
    this.data = null;
    this.loadingState = LoadingType.INITIAL;
  }

  @computed
  get dataTableEntries(): string[][] {
    if (this.data && this.data.length > 0) {
      return this.data
        ?.reverse()
        .map((entry) => [
          entry.timestamp,
          entry.type === 0 ? 'incoming' : 'outgoing',
          entry.message,
        ]);
    }
    return [];
  }
}
