import {
  OKAI_DEVICES,
  OMNIOM0000,
  OMNI_DEVICES,
  SEGWAY_DEVICES,
  YADEA_DEVICES,
  YADEYA0001
} from '@wunder/tools-iot-connector-device-types';

export const OKAI = {
  supportedFields: [
    'iot.accelerometerSensitivity',
    'iot.deviceStatusWhenUnlockedInterval',
    'iot.positionUploadInterval',
    'settings.alarmActive',
    'settings.inchSpeedDisplay',
    'settings.startupModeSetting',
    'settings.highSpeedModeSpeedLimit'
  ],
  types: OKAI_DEVICES
};

export const OMNI = {
  supportedFields: [
    'iot.accelerometerSensitivity',
    'iot.heartbeatUploadInterval',
    'iot.reportDeviceStatusWhenUnlocked',
    'iot.deviceStatusWhenUnlockedInterval',
    'iot.positionUploadInterval',
    'settings.inchSpeedDisplay',
    'settings.cruiseControlSetting',
    'settings.startupModeSetting',
    'settings.buttonSwitchingSpeedMode',
    'settings.keySwitchHeadlight',
    'settings.lowSpeedModeSpeedLimit',
    'settings.mediumSpeedModeSpeedLimit',
    'settings.highSpeedModeSpeedLimit',
    'rideSettings.headlightSwitch',
    'rideSettings.throttleResponse',
    'rideSettings.taillightsFlashing',
    'rideSettings.modeSetting'
  ],
  types: OMNI_DEVICES
};

export const YADEA = {
  supportedFields: [],
  types: YADEA_DEVICES
};

export const YADEA_G5L = {
  supportedFields: ['settings.alarmActive', 'settings.alarmVolume', 'settings.alarmSensitivity'],
  types: YADEA_DEVICES
};

export const OMNI_BIKE_LOCK_OGB1 = {
  supportedFields: [
    'iot.reportDeviceStatusWhenUnlocked',
    'iot.deviceStatusWhenUnlockedInterval',
    'iot.positionUploadInterval'
  ],
  types: OMNIOM0000
};

export function getSupportedFieldNames(deviceType: string): string[] {
  if (OKAI_DEVICES.find((type) => type.id === deviceType)) {
    return OKAI.supportedFields;
  }

  if (OMNI_DEVICES.find((type) => type.id === deviceType)) {
    return deviceType === OMNIOM0000.id
      ? OMNI_BIKE_LOCK_OGB1.supportedFields
      : OMNI.supportedFields;
  }

  if (SEGWAY_DEVICES.find((type) => type.id === deviceType)) {
    return OMNI.supportedFields;
  }

  if (YADEA_DEVICES.find((type) => type.id === deviceType)) {
    return deviceType === YADEYA0001.id ? YADEA_G5L.supportedFields : YADEA.supportedFields;
  }

  return [];
}
