import { DeviceCommandInstruction } from '@wunder/tools-iot-connector-device-types';

export enum DeviceListBatchAction {
  remove = 'REMOVE'
}

export type DeviceListBatchActionId = DeviceCommandInstruction | DeviceListBatchAction;

export type CommandActionListItemType = {
  id: DeviceListBatchActionId;
  name: string;
  command: DeviceCommandInstruction;
};

export const sendGeneralCommandActionListItems: CommandActionListItemType[] = [
  {
    command: DeviceCommandInstruction.alarm,
    id: DeviceCommandInstruction.alarm,
    name: 'Play alarm sound'
  },
  { command: DeviceCommandInstruction.honk, id: DeviceCommandInstruction.honk, name: 'Honk' },
  {
    command: DeviceCommandInstruction.currentPosition,
    id: DeviceCommandInstruction.currentPosition,
    name: 'Get current position'
  },
  {
    command: DeviceCommandInstruction.info,
    id: DeviceCommandInstruction.info,
    name: 'Get device information'
  },
  {
    command: DeviceCommandInstruction.firmware,
    id: DeviceCommandInstruction.firmware,
    name: 'Get firmware version'
  },
  {
    command: DeviceCommandInstruction.normalMode,
    id: DeviceCommandInstruction.normalMode,
    name: 'Switch to Normal mode'
  },
  {
    command: DeviceCommandInstruction.testMode,
    id: DeviceCommandInstruction.testMode,
    name: 'Switch to Test mode'
  },
  {
    command: DeviceCommandInstruction.changePassword,
    id: DeviceCommandInstruction.changePassword,
    name: 'Change password'
  },
  {
    command: DeviceCommandInstruction.turnOffIot,
    id: DeviceCommandInstruction.turnOffIot,
    name: 'Turn off IoT'
  },
  {
    command: DeviceCommandInstruction.rebootIot,
    id: DeviceCommandInstruction.rebootIot,
    name: 'Reboot IoT'
  },
  {
    command: DeviceCommandInstruction.volumeOn,
    id: DeviceCommandInstruction.volumeOn,
    name: 'Activate sounds'
  },
  {
    command: DeviceCommandInstruction.volumeOff,
    id: DeviceCommandInstruction.volumeOff,
    name: 'Deactivate sounds'
  },
  {
    command: DeviceCommandInstruction.lightsOn,
    id: DeviceCommandInstruction.lightsOn,
    name: 'Lights on'
  },
  {
    command: DeviceCommandInstruction.lightsOff,
    id: DeviceCommandInstruction.lightsOff,
    name: 'Lights off'
  },
  {
    command: DeviceCommandInstruction.throttleOn,
    id: DeviceCommandInstruction.throttleOn,
    name: 'Throttle On'
  },
  {
    command: DeviceCommandInstruction.throttleOff,
    id: DeviceCommandInstruction.throttleOff,
    name: 'Throttle Off'
  },
  {
    command: DeviceCommandInstruction.firmwareUpdate,
    id: DeviceCommandInstruction.firmwareUpdate,
    name: 'Update Firmware'
  }
];

export const sendLockCommandActionListItems: CommandActionListItemType[] = [
  {
    command: DeviceCommandInstruction.lock,
    id: DeviceCommandInstruction.lock,
    name: 'Lock device'
  },
  {
    command: DeviceCommandInstruction.unlock,
    id: DeviceCommandInstruction.unlock,
    name: 'Unlock device'
  },
  {
    command: DeviceCommandInstruction.lockAndImmobilise,
    id: DeviceCommandInstruction.lockAndImmobilise,
    name: 'Lock and immobilise device'
  },
  {
    command: DeviceCommandInstruction.unlockAndMobilise,
    id: DeviceCommandInstruction.unlockAndMobilise,
    name: 'Unlock and mobilise device'
  },
  {
    command: DeviceCommandInstruction.batteryLock,
    id: DeviceCommandInstruction.batteryLock,
    name: 'Lock battery compartment'
  },
  {
    command: DeviceCommandInstruction.batteryUnlock,
    id: DeviceCommandInstruction.batteryUnlock,
    name: 'Unlock battery compartment'
  },
  {
    command: DeviceCommandInstruction.cableLock,
    id: DeviceCommandInstruction.cableLock,
    name: 'Lock Cable lock'
  },
  {
    command: DeviceCommandInstruction.cableUnlock,
    id: DeviceCommandInstruction.cableUnlock,
    name: 'Unlock Cable lock'
  },
  {
    command: DeviceCommandInstruction.saddleLock,
    id: DeviceCommandInstruction.saddleLock,
    name: 'Lock Saddle lock'
  },
  {
    command: DeviceCommandInstruction.saddleUnlock,
    id: DeviceCommandInstruction.saddleUnlock,
    name: 'Unlock Saddle lock'
  },
  {
    command: DeviceCommandInstruction.tailboxLock,
    id: DeviceCommandInstruction.tailboxLock,
    name: 'Lock Tailbox lock'
  },
  {
    command: DeviceCommandInstruction.tailboxUnlock,
    id: DeviceCommandInstruction.tailboxUnlock,
    name: 'Unlock Tailbox lock'
  }
];
