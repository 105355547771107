import { ACTOOM0000 } from '@wunder/tools-iot-connector-device-types';
import { FormField } from '../formHelper/formFieldTypes';

type DeviceTypeConfigurationField = {
  deviceTypeId: string;
  iotSettingsFields?: FormField[];
  deviceSettingsFields?: FormField[];
  rideSettingsFields?: FormField[];
};

export const deviceTypeConfigurationFields: DeviceTypeConfigurationField[] = [
  {
    deviceTypeId: ACTOOM0000.id,
    deviceSettingsFields: [
      {
        name: 'settings.lowSpeedModeSpeedLimit',
        options: { max: 30, min: 6, step: 1 },
        translationKey: 'deviceConfiguration.settings.lowSpeedModeSpeedLimit',
        type: 'number',
        unit: 'km/h',
      },
      {
        name: 'settings.mediumSpeedModeSpeedLimit',
        options: { max: 30, min: 6, step: 1 },
        translationKey: 'deviceConfiguration.settings.mediumSpeedModeSpeedLimit',
        type: 'number',
        unit: 'km/h',
      },
      {
        name: 'settings.highSpeedModeSpeedLimit',
        options: { max: 30, min: 6, step: 1 },
        translationKey: 'deviceConfiguration.settings.highSpeedModeSpeedLimit',
        type: 'number',
        unit: 'km/h',
      },
    ],
  },
];
