import { StyleObject, StyletronComponent } from 'styletron-react';

import { DeviceConfigurationParams } from '../../models/deviceConfiguration';
import { themedStyled } from '../../theme/theme';

export enum ViewState {
  'list',
  'edit',
  'new'
}

export const defaultConfiguration: DeviceConfigurationParams = {
  iot: {
    accelerometerSensitivity: 2,
    deviceStatusWhenUnlockedInterval: 10,
    heartbeatUploadInterval: 240,
    positionUploadInterval: 300,
    reportDeviceStatusWhenUnlocked: false
  },
  name: 'New Configuration',
  rideSettings: {
    headlightSwitch: 0,
    modeSetting: 2,
    taillightsFlashing: 0,
    throttleResponse: 0
  },
  settings: {
    alarmActive: true,
    alarmSensitivity: 1,
    alarmVolume: 1,
    buttonSwitchingSpeedMode: false,
    cruiseControlSetting: false,
    highSpeedModeSpeedLimit: 25,
    inchSpeedDisplay: false,
    keySwitchHeadlight: false,
    lowSpeedModeSpeedLimit: 15,
    mediumSpeedModeSpeedLimit: 20,
    startupModeSetting: false
  }
};

export const Container: StyletronComponent<any> = themedStyled(
  'section',
  (): StyleObject => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  })
);

export const Headline: StyletronComponent<any> = themedStyled(
  'h1',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingMediumRegular,
    flexShrink: 0,
    marginBottom: $theme.sizes.scale500,
    marginLeft: $theme.sizes.scale400,
    marginRight: $theme.sizes.scale400,
    marginTop: $theme.sizes.scale500
  })
);

export const Content: StyletronComponent<any> = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    background: $theme.colors.bgWhite,
    flexGrow: 1
  })
);

export const Footer: StyletronComponent<any> = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'flex-end',
    paddingBottom: $theme.sizes.scale500,
    paddingLeft: $theme.sizes.scale400,
    paddingRight: $theme.sizes.scale400,
    paddingTop: $theme.sizes.scale500
  })
);

export const HeadlineRow: StyletronComponent<any> = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    alignItems: 'center',
    display: 'flex',
    marginBottom: $theme.sizes.scale400,
    marginRight: $theme.sizes.scale400,
    marginTop: $theme.sizes.scale400
  })
);
