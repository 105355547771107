import { toaster } from 'baseui/toast';
import { useObserver } from 'mobx-react';
import React, { useState } from 'react';
import { StyleObject } from 'styletron-react';

import { useStores } from '../../hooks/use-stores';
import { DeviceStore } from '../../stores';
import { AddNewIcon } from '../../theme/icons';
import { themedStyled, themedUseStyletron as useStyletron } from '../../theme/theme';
import { Button } from '../button/Button';

import { AddNewDevicesModal, AddNewDevicesValues } from './AddNewDevicesModal';

const StyledButton = themedStyled(
  Button,
  ({ $theme }): StyleObject => ({
    marginRight: $theme.sizes.scale200,
  }),
);

function displaySuccessToast(deviceStore: DeviceStore): void {
  const createdDevicesCount = deviceStore.createdDeviceIds.length;

  if (createdDevicesCount) {
    const imeis = createdDevicesCount === 1 ? `IMEI` : `IMEIs`;

    toaster.positive(
      <>
        {createdDevicesCount} {imeis} added to the device list successfuly
      </>,
      {
        autoHideDuration: 4000,
      },
    );
  }
}

export const AddNewDevices: React.FC = () => {
  return useObserver(() => {
    const [, theme] = useStyletron();
    const [open, setOpen] = useState(false);
    const { deviceStore, tenantStore, userStore } = useStores();

    const onSubmit = async (values: AddNewDevicesValues) => {
      await deviceStore.createDevicesHandler(values.deviceIds);
      displaySuccessToast(deviceStore);
      setOpen(false);
    };

    if (tenantStore.currentTenant?.enableAutomaticDeviceCreation) {
      return null;
    }

    const disabled = !userStore.currentUser?.hasWriteAccessOnSelectedTenant();

    return (
      <>
        <AddNewDevicesModal open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />
        <StyledButton
          variant="primary"
          onClick={() => setOpen(true)}
          disabled={disabled}
          icon={(): JSX.Element => (
            <AddNewIcon
              fill={disabled ? '#667380' : 'white'}
              width={theme.sizing.scale600}
              height={theme.sizing.scale600}
            />
          )}
        >
          Add new IMEIs
        </StyledButton>
      </>
    );
  });
};
