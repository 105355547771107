import React from 'react';

import { DeviceStore } from '../../stores';
import { Pagination } from '../pagination/Pagination';

export const DeviceListFooter = ({
  onPrev,
  onNext,
  onSizeChange,
  deviceStore,
}: {
  onSizeChange: (value: number) => void;
  onPrev: () => void;
  onNext: () => void;
  deviceStore: DeviceStore;
}): JSX.Element | null => {
  function onPageSizeChange(value: number) {
    deviceStore.resetPaginationPosition();
    deviceStore.setPaginationSize(value);
    onSizeChange(value);
  }

  function onPrevClicked() {
    deviceStore.loadPrev();
    onPrev();
  }

  function onNextClicked() {
    deviceStore.loadNext();
    onNext();
  }

  return (
    <Pagination
      page={deviceStore.page}
      pageSize={deviceStore.pageSize}
      total={deviceStore.totalDevices}
      onPageSizeChange={onPageSizeChange}
      onPrev={onPrevClicked}
      onNext={onNextClicked}
      hasPrev={deviceStore.hasPrev}
      hasNext={deviceStore.hasMore}
      perPageLabel="Devices per page"
      recordsLabel="devices"
    />
  );
};
