import path from 'path';

import { DeviceCommandInstruction } from '@wunder/tools-iot-connector-device-types';

import { TenantDocument } from '../models';

import { config } from './config';

const DEFAULT_API_KEY = 'wunderToolsCloud';

export interface CommandResponse {
  success: boolean;
  error?: string;
}

export async function sendCommand(
  deviceId: string,
  tenant: TenantDocument,
  command: DeviceCommandInstruction,
  payload?: Record<string, unknown>,
): Promise<CommandResponse> {
  const tenantData = tenant;
  if (tenantData) {
    const {
      iotConnector: { apiKey, baseUrl },
    } = tenantData;
    const url = new URL(baseUrl);
    url.pathname = path.join(url.pathname, `/devices/${deviceId}/command`);

    if (config.features.skipDeviceCommandCheck) {
      url.searchParams.append('skip', 'true');
    }

    try {
      const response = await fetch(url.href, {
        body: JSON.stringify({
          instruction: command,
          payload,
        }),
        headers: {
          'Content-Type': 'application/json',
          'fleetbird-api-key': apiKey ?? DEFAULT_API_KEY,
        },
        method: 'POST',
      });

      const status: { success: boolean } = await response.json();
      return status;
    } catch (err) {
      return {
        error: `Error occurred when sending command to device ${deviceId}`,
        success: false,
      };
    }
  }

  return {
    error: 'No tenant selected',
    success: false,
  };
}
