import React from 'react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';
import { TileType } from '../../typings/tile';

import { SyncStatusTile } from './syncStatusTile';

export interface SyncStatusPanelProps {
  devicesWithConfig: number;
  devicesWithOutConfig: number;
  longInactiveDevices: number;
  shortTermInactiveDevices: number;
  applyFilter: (tile: TileType) => void;
  selectedTile: TileType;
}

export interface TileStatusType {
  isSelected: boolean;
  type: TileType;
}

export const SyncStatusPanel = ({
  devicesWithConfig,
  devicesWithOutConfig,
  longInactiveDevices,
  shortTermInactiveDevices,
  applyFilter,
  selectedTile,
}: SyncStatusPanelProps): JSX.Element => {
  const [, theme] = useStyletron();

  const totalDevices = devicesWithConfig + devicesWithOutConfig;

  const calculatePercentage = (numberOfSelectedDevices: number): number => {
    return Math.round((numberOfSelectedDevices / totalDevices) * 100);
  };

  return (
    <>
      {totalDevices >= 0 && (
        <SyncStatusTile
          headlineText={`${totalDevices}`}
          subHeadlineText="total devices"
          isSelected={selectedTile === TileType.Total}
          onClick={(): void => applyFilter(TileType.Total)}
        />
      )}

      {devicesWithConfig > 0 && (
        <SyncStatusTile
          headlineText={devicesWithConfig.toString()}
          subHeadlineText={`${calculatePercentage(devicesWithConfig)}% with config`}
          isSelected={selectedTile === TileType.WithConfig}
          accentColor={theme.colors.success100}
          onClick={(): void => applyFilter(TileType.WithConfig)}
        />
      )}

      {devicesWithOutConfig > 0 && (
        <SyncStatusTile
          headlineText={devicesWithOutConfig.toString()}
          subHeadlineText={`${calculatePercentage(devicesWithOutConfig)}% without config`}
          isSelected={selectedTile === TileType.WithoutConfig}
          accentColor={theme.colors.warning100}
          onClick={(): void => applyFilter(TileType.WithoutConfig)}
        />
      )}

      {longInactiveDevices > 0 && (
        <SyncStatusTile
          headlineText={longInactiveDevices.toString()}
          subHeadlineText={`${calculatePercentage(longInactiveDevices)}% inactive more than 2d`}
          isSelected={selectedTile === TileType.LongInactiveDevices}
          accentColor={theme.colors.error100}
          onClick={(): void => applyFilter(TileType.LongInactiveDevices)}
        />
      )}

      {shortTermInactiveDevices > 0 && (
        <SyncStatusTile
          headlineText={shortTermInactiveDevices.toString()}
          subHeadlineText={`${calculatePercentage(shortTermInactiveDevices)}% 2d, < 15m inactive`}
          isSelected={selectedTile === TileType.ShortTermInactiveDevices}
          accentColor={theme.colors.error100}
          onClick={(): void => applyFilter(TileType.ShortTermInactiveDevices)}
        />
      )}
    </>
  );
};
