import { observable } from 'mobx';

import { FetchDocumentationsResponse, IOTClient } from '../client/interfaces';

export class FeatureStore {
  @observable activeFeatures: Record<string, unknown> = {};

  documentations: FetchDocumentationsResponse | undefined;

  constructor(readonly iotClient: IOTClient, features: Record<string, unknown>) {
    this.activeFeatures = features;
  }

  isActive(featureName: string): boolean {
    return !!this.activeFeatures[featureName];
  }

  async getDocumentation(tenantId: string): Promise<FetchDocumentationsResponse> {
    this.documentations = await this.iotClient.fetchDocumentations(tenantId);
    return this.documentations;
  }
}
