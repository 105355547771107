export type Endpoint = {
  address: string;
  port: number;
};

export interface TenantConfig {
  id?: string;
  name?: string;
  endpoint?: Endpoint;
  devicePassword?: string;
  iotConnector: {
    baseUrl: string;
    apiKey: string;
  };
  fleet: {
    webhookApiKey: string;
    webhookUrl: string;
  };
  enableAutomaticDeviceCreation?: boolean;
  deviceUpdateTimeout?: number;
  deviceEncryptionKey?: string;
  deviceEncryptionIv?: string;
}

export class TenantDocument {
  public id?: string;

  public name?: string;

  public endpoint?: Endpoint;

  public devicePassword?: string;

  public enableAutomaticDeviceCreation?: boolean;

  public deviceUpdateTimeout?: number;

  public deviceEncryptionKey?: string;

  public deviceEncryptionIv?: string;

  public iotConnector: {
    baseUrl: string;
    apiKey: string;
  };

  public fleet: {
    webhookApiKey: string;
    webhookUrl: string;
  };

  constructor(params: TenantConfig) {
    this.id = params.id;
    this.name = params.name;
    this.endpoint = params.endpoint;
    this.devicePassword = params.devicePassword;
    this.enableAutomaticDeviceCreation = params.enableAutomaticDeviceCreation;
    this.deviceUpdateTimeout = params.deviceUpdateTimeout ?? 25;
    this.iotConnector = params.iotConnector;
    this.fleet = params.fleet;
    this.deviceEncryptionKey = params.deviceEncryptionKey;
    this.deviceEncryptionIv = params.deviceEncryptionIv;
  }

  get displayName(): string {
    return this.name || (this.id as string);
  }
}
