import { FormControl } from 'baseui/form-control';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TranslatedFormControl = ({
  label,
  translationKey,
  error,
  children,
}: {
  label?: string;
  translationKey?: string;
  error?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormControl
      label={(): string => label || t(`${translationKey}.name`)}
      error={error}
      caption={translationKey && t(`${translationKey}.tooltip`)}
    >
      {children}
    </FormControl>
  );
};
