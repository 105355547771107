import React from 'react';

import { DialogForm } from '../formHelper/DialogForm';
import { CloseHandler, SubmitHandler } from '../formHelper/DialogFormBase';
import { FormField } from '../formHelper/formFieldTypes';

const formFields: FormField[] = [
  {
    label: 'Device IMEIs',
    name: 'deviceIds',
    placeholder: 'Enter IMEIs separated by comma.\nE.g: 990000862470001, 990000862470002, ...',
    type: 'textarea',
    validation: (value: unknown): string | undefined =>
      (value as string) === '' ? 'Please provide at least one IMEI' : undefined
  }
];

export interface AddNewDevicesValues {
  deviceIds: string;
}

export interface AddNewDevicesModalProps {
  open: boolean;
  onClose: CloseHandler;
  onSubmit: SubmitHandler<AddNewDevicesValues>;
}

export function AddNewDevicesModal(props: AddNewDevicesModalProps): JSX.Element {
  const { open, onClose, onSubmit } = props;

  return (
    <DialogForm
      fields={formFields}
      initialValues={{ deviceIds: '' }}
      loading={false}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      size='large'
      title='Add New IMEIs'
    />
  );
}
