import { FormControl } from 'baseui/form-control';
import { Textarea } from 'baseui/textarea';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledModalBody, CenterContainer } from '../modal/ModalHelper';

const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  // allow text identation
  if (e.key === 'Tab') {
    e.preventDefault();

    const start = e.currentTarget.selectionStart;
    const end = e.currentTarget.selectionEnd;

    // set textarea value to: text before caret + tab + text after caret
    e.currentTarget.value = `${e.currentTarget.value.substring(
      0,
      start,
    )}\t${e.currentTarget.value.substring(end)}`;

    // put caret at right position again
    e.currentTarget.selectionStart = start + 1;
    e.currentTarget.selectionEnd = start + 1;
  }
};

const placeholder = `{
  "updateType": "...",
  "url": "...",
  "deviceAuthKey": "..."
}`;

interface FirmwareModalBodyProps {
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  firmwarePayload: string;
  isInputValid: boolean;
}

export const FirmwareModalBody = ({
  firmwarePayload,
  onChange,
  isInputValid,
}: FirmwareModalBodyProps): JSX.Element => {
  const { t } = useTranslation();

  const hintText = t('modals.firmware.hint');
  const errorText = !isInputValid && firmwarePayload !== '' ? t('modals.firmware.error') : '';

  return (
    <StyledModalBody>
      <CenterContainer>
        <FormControl
          label={() => t('modals.firmware.label')}
          error={!isInputValid && firmwarePayload !== ''}
          caption={() => errorText || hintText}
        >
          <Textarea
            overrides={{
              InputContainer: {
                style: {
                  height: `200px`,
                },
              },
            }}
            onKeyDown={onKeyDown}
            value={firmwarePayload}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus
            error={!isInputValid}
          />
        </FormControl>
      </CenterContainer>
    </StyledModalBody>
  );
};
