import { Input } from 'baseui/input';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { ArrowLeftIcon, CheckIcon, EditIcon } from '../../theme/icons';
import { themedUseStyletron as useStyletron } from '../../theme/theme';
import { Button } from '../button/Button';

import { ViewState, HeadlineRow } from './DeviceConfigurationHelper';

interface DeviceConfigurationSettingsHeadlineProps {
  updateCurrentView: (view: ViewState) => void;
  nameInput: string;
  setNameInput: React.Dispatch<React.SetStateAction<string>>;
}

export const DeviceConfigurationSettingsHeadline = ({
  updateCurrentView,
  nameInput,
  setNameInput,
}: DeviceConfigurationSettingsHeadlineProps): JSX.Element => {
  const [, theme] = useStyletron();
  const headlineInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <HeadlineRow>
      <Button
        variant="text"
        icon={ArrowLeftIcon}
        style={{
          paddingLeft: theme.sizes.scale400,
          paddingRight: theme.sizes.scale400,
        }}
        onClick={(): void => updateCurrentView(ViewState.list)}
      />
      <Input
        value={nameInput}
        inputRef={headlineInputRef}
        onChange={(value): void => setNameInput(value.currentTarget.value)}
        endEnhancer={({ $isFocused }): JSX.Element => {
          return $isFocused ? (
            <Button
              variant="text"
              icon={CheckIcon}
              style={{ marginLeft: `-${theme.sizes.scale200}` }}
              type="button"
            />
          ) : (
            <Button
              variant="text"
              icon={EditIcon}
              style={{ marginLeft: `-${theme.sizes.scale200}` }}
              onClick={(): void => headlineInputRef.current?.focus()}
              type="button"
            />
          );
        }}
        overrides={{
          Input: {
            style: (): StyleObject => ({
              backgroundColor: theme.colors.bg10,
              ':hover:not(:focus)': {
                backgroundColor: theme.colors.bg25,
              },
            }),
          },
          InputContainer: {
            style: ({ $isFocused }): StyleObject => ({
              borderTopColor: $isFocused ? theme.colors.primary100 : theme.colors.bg10,
              borderRightColor: $isFocused ? theme.colors.primary100 : theme.colors.bg10,
              borderBottomColor: $isFocused ? theme.colors.primary100 : theme.colors.bg10,
              borderLeftColor: $isFocused ? theme.colors.primary100 : theme.colors.bg10,
            }),
          },
          EndEnhancer: {
            style: (): StyleObject => ({
              backgroundColor: 'transparent',
            }),
          },
        }}
      />
    </HeadlineRow>
  );
};
