import { ChevronLeft, ChevronRight } from 'baseui/icon';
import { StyledLink } from 'baseui/link';
import { OnChangeParams, Select, SIZE } from 'baseui/select';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron } from '../../theme/theme';

const AVAILABLE_PAGE_SIZES = [5, 10, 25, 50, 100];

export interface PaginationProps {
  page: number;
  pageSize: number;
  total?: number;
  onPageSizeChange: (value: number) => void;
  onPrev: () => void;
  onNext: () => void;
  hasPrev?: boolean;
  hasNext?: boolean;
  perPageLabel?: string;
  recordsLabel?: string;
}

interface DisplayedItemsProps {
  page: number;
  pageSize: number;
  total?: number;
  recordsLabel: string;
}

const DisplayedItems: React.FC<DisplayedItemsProps> = ({
  page,
  pageSize,
  total,
  recordsLabel,
}: DisplayedItemsProps) => {
  const startItem = page * pageSize + 1;
  const endItem = startItem + pageSize - 1;

  if (total) {
    return (
      <div>{`${startItem} - ${endItem < total ? endItem : total} of ${total} ${recordsLabel}`}</div>
    );
  }

  return <div>{`${startItem} - ${endItem} ${recordsLabel}`}</div>;
};

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const {
    page,
    pageSize,
    total,
    onPageSizeChange,
    onPrev,
    onNext,
    hasPrev = true,
    hasNext = true,
    perPageLabel = 'Records per page',
    recordsLabel = 'records',
  } = props;

  const [css, theme] = themedUseStyletron();

  return (
    <div
      className={css({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        ...theme.typography.labelCaptionRegular,
      })}
    >
      <div>
        <span>{perPageLabel}</span>
        <Select
          overrides={{
            ControlContainer: {
              style: (): StyleObject => ({
                borderBottomColor: 'transparent',
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent',
                borderTopColor: 'transparent',
                boxShadow: 'none',
              }),
            },
            InputContainer: {
              style: {
                marginLeft: 0,
              },
            },
            Root: {
              style: (): StyleObject => ({
                display: 'inline-block',
                paddingLeft: theme.sizes.scale100,
                paddingRight: theme.sizes.scale100,
                width: 'auto',
                ...theme.typography.labelCaptionRegular,
              }),
            },
          }}
          size={SIZE.compact}
          value={[{ id: pageSize }]}
          onChange={(event: OnChangeParams): void => {
            if (event.option && typeof event.option.id === 'number') {
              onPageSizeChange(event.option.id);
            }
          }}
          clearable={false}
          searchable={false}
          options={AVAILABLE_PAGE_SIZES.map((size) => ({
            id: size,
            label: size,
          }))}
        />
      </div>
      <DisplayedItems page={page} pageSize={pageSize} total={total} recordsLabel={recordsLabel} />
      <StyledLink
        onClick={(): void => {
          if (hasPrev) {
            onPrev();
          }
        }}
      >
        <ChevronLeft size={24} color={hasPrev ? theme.colors.text100 : theme.colors.text25} />
      </StyledLink>

      <StyledLink
        onClick={(): void => {
          if (hasNext) {
            onNext();
          }
        }}
      >
        <ChevronRight size={24} color={hasNext ? theme.colors.text100 : theme.colors.text25} />
      </StyledLink>
    </div>
  );
};
