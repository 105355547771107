import { Table } from 'baseui/table-semantic';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { WarningMessage } from '../modal/ModalHelper';

export const DeviceLogModalContent = ({
  data,
  error,
  columns,
}: {
  data: string[][];
  error?: boolean;
  columns: string[];
}): JSX.Element => {
  if (error) {
    return (
      <WarningMessage>
        Error Loading Data{' '}
        <span role="img" aria-label="Crying Emoji">
          😢
        </span>
      </WarningMessage>
    );
  }

  if (data.length === 0) {
    return (
      <WarningMessage>
        No Data Available{' '}
        <span role="img" aria-label="Sad Monkey Emoji">
          🙈
        </span>
      </WarningMessage>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      overrides={{
        Root: {
          style: (): StyleObject => ({
            maxHeight: `100%`,
            width: '100%',
          }),
        },
      }}
    />
  );
};
