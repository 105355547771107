import {
  Textarea as BaseWebTextarea,
  TextareaProps as BaseWebTextareaProps,
} from 'baseui/textarea';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';

export const Textarea = ({ ...others }: BaseWebTextareaProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <BaseWebTextarea
      {...others}
      overrides={{
        Input: {
          style: (): StyleObject => {
            return {
              ...theme.typography.contentBodyRegular,
              paddingTop: theme.sizes.scale300,
              paddingRight: theme.sizes.scale500,
              paddingBottom: theme.sizes.scale300,
              paddingLeft: theme.sizes.scale200,
              color: theme.colors.text100,
              minHeight: theme.sizes.scale750,
              resize: 'vertical',
              '::placeholder': {
                color: theme.colors.text50,
              },
            };
          },
        },
      }}
    />
  );
};
