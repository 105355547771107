import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleObject } from 'styletron-react';

import { DeviceDocument } from '../../models';
import { CopyIcon } from '../../theme/icons';
import { themedStyled } from '../../theme/theme';
import { copyToClipboard } from '../../utils/clipboard';
import { Button } from '../button/Button';
import { ButtonVariant } from '../button/buttonStyles';

const CopyButton = themedStyled(
  Button,
  ({ $theme }): StyleObject => ({
    marginRight: $theme.sizes.scale200,
  }),
);

interface DeviceListCopyIMEIProps {
  devices: DeviceDocument[];
  variant?: ButtonVariant;
}

export const DeviceListCopyIMEI = ({
  devices,
  variant = 'primary',
}: DeviceListCopyIMEIProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CopyButton
      variant={variant}
      disabled={devices.length === 0}
      icon={CopyIcon}
      onClick={(): void => {
        const filteredIMEIs = devices.map((device) => device.id);

        copyToClipboard({
          errorMessage: t('deviceList.toast.copyIMEIs.error'),
          successMessage: t('deviceList.toast.copyIMEIs.success'),
          text: filteredIMEIs.join(),
        });
      }}
    >
      Copy IMEI&apos;s
    </CopyButton>
  );
};
