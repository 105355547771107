import configData from '../config/index.json';

class Config {
  readonly environment: string;

  readonly features: Record<string, unknown>;

  readonly authApi: string;

  readonly deviceLogApi: string;

  constructor() {
    const { features, deviceLogApi, environment, authApi } = configData;
    this.features = features;
    this.deviceLogApi = deviceLogApi;
    this.environment = environment;
    this.authApi = authApi;
  }
}

export const config = new Config();
