import { action, computed, observable } from 'mobx';

import { DeviceType as IDeviceType, getDeviceType } from '@wunder/tools-iot-connector-device-types';

import { IOTClient } from '../client/interfaces';
import { DeviceDocument, DeviceParams } from '../models';
import { TileType } from '../typings/tile';

import { DeviceConfigurationStore } from './deviceConfigurationStore';

const DEFAULT_PAGE_SIZE = 25;
const INITIAL_PAGE = 0;
const SIZES = [5, 10, 25, 50, 100];

export type DeviceFilters = {
  deviceIds?: string[];
  typeRefIds?: string[];
  configurationRefIds?: string[];
};

export enum FilterTypes {
  DeviceIds = 'DeviceIds',
  DeviceTypeIds = 'DeviceTypeIds',
  ConfigurationRefIds = 'ConfigurationRefIds',
  WithConfig = 'WithConfig',
  WithoutConfig = 'WithoutConfig',
  LongInactiveDevices = 'LongInactive',
  ShortTermInactiveDevices = 'ShortTermInactive',
  FirmwareVersions = 'FirmwareVersions'
}

export enum SortColumn {
  DeviceId = 'DeviceId',
  DeviceType = 'DeviceType',
  Configuration = 'Configuration',
  LastActive = 'LastActive'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export interface Filter {
  type: FilterTypes;
  value?: string[];
}

export class DeviceStore {
  readonly deviceConfigurationStore: DeviceConfigurationStore;

  private tenantId: string | null = null;

  private filters: Filter[] = [];

  @observable devices: DeviceParams[] | null = null;

  @observable devicesReady = false;

  @observable page = INITIAL_PAGE;

  @observable pageSize = DEFAULT_PAGE_SIZE;

  @observable totalDevices = 0;

  @observable totalDevicesWithConfig = 0;

  @observable totalDevicesWithoutConfig = 0;

  @observable longInactiveDevices = 0;

  @observable shortTermInactiveDevices = 0;

  @observable selectedTile: TileType = TileType.Total;

  @observable sortColumn = SortColumn.DeviceId;

  @observable sortDirection = SortDirection.Asc;

  @observable invalidDeviceIds: string[] = [];

  @observable createdDeviceIds: string[] = [];

  @observable addNewDevicesFinished = false;

  @observable availableFirmwareVersions: string[] = [];

  @observable availableDeviceTypeIds: IDeviceType[] = [];

  constructor(readonly iotClient: IOTClient, deviceConfigurationStore: DeviceConfigurationStore) {
    this.deviceConfigurationStore = deviceConfigurationStore;
  }

  @action.bound
  async initData(tenantId: string): Promise<void> {
    if (this.tenantId !== tenantId) {
      this.page = INITIAL_PAGE;
      this.tenantId = tenantId;
      await this.fetchDevicesStatus();
    }
  }

  private async fetchDevices(updateLoadingState: boolean = true) {
    if (updateLoadingState) {
      this.devicesReady = false;
    }
    if (this.tenantId) {
      try {
        await this.deviceConfigurationStore.initData(this.tenantId);
        const filters: Filter[] = this.filters.filter((f) => f.value?.length !== 0);
        const { success, devices, totalDevices } = await this.iotClient.listDevices(
          this.tenantId,
          this.page,
          this.pageSize,
          SortColumn.DeviceId,
          SortDirection.Asc,
          filters
        );
        if (success) {
          this.devices = devices || [];
          this.totalDevices = totalDevices || 0;
        }
      } catch (e) {
        console.log('Error when fetching Devices: ', e);
      }
    }

    if (updateLoadingState) {
      await this.fetchFilters();
      this.devicesReady = true;
    }
  }

  private async fetchFilters() {
    this.availableFirmwareVersions = await this.getFilters(FilterTypes.FirmwareVersions);

    this.availableDeviceTypeIds = (await this.getFilters(FilterTypes.DeviceTypeIds)).map(
      (deviceTypeId: string | undefined) => getDeviceType(deviceTypeId)
    );
  }

  private async fetchDevicesStatus() {
    if (this.tenantId) {
      try {
        const { success, devicesStatus } = await this.iotClient.getDevicesStatus(this.tenantId);

        if (success && devicesStatus) {
          this.totalDevicesWithConfig = devicesStatus.totalDevicesWithConfig;
          this.totalDevicesWithoutConfig = devicesStatus.totalDevicesWithoutConfig;
          this.longInactiveDevices = devicesStatus.longInactiveDevices;
          this.shortTermInactiveDevices = devicesStatus.shortTermInactiveDevices;
        }
        await this.fetchDevices();
      } catch (e) {
        console.log(e);
      }
    }
  }

  private async getFilters(filterType: FilterTypes): Promise<string[]> {
    if (this.tenantId) {
      const uniqueValues = await this.iotClient.getFilters(this.tenantId, filterType);
      return uniqueValues as unknown as string[];
    }
    return [];
  }

  @action.bound
  async cleanUp(): Promise<void> {
    this.devices = null;
    this.filters = [];
    this.setSelectedTile(TileType.Total);
  }

  @action.bound
  async replaceFilters(allFilters: Filter[]): Promise<void> {
    this.filters = allFilters;
    this.resetPaginationPosition();
    await this.fetchDevices();
  }

  @action.bound
  async removeAllFilter(): Promise<void> {
    this.filters = [];
    await this.fetchDevices();
  }

  @action.bound
  async setPaginationSize(size: number): Promise<void> {
    this.pageSize = size;
    await this.fetchDevices();
  }

  @action.bound
  async loadNext(): Promise<void> {
    this.page += 1;
    await this.fetchDevices();
  }

  @action.bound
  async loadPrev(): Promise<void> {
    this.page -= 1;
    await this.fetchDevices();
  }

  @action.bound
  resetPaginationPosition(): void {
    this.page = INITIAL_PAGE;
  }

  @action.bound
  async sortDevices(column: SortColumn, direction: SortDirection): Promise<void> {
    this.sortColumn = column;
    this.sortDirection = direction;
  }

  @action.bound
  onActionTagClick(filterType: FilterTypes, filterValues: string[]): void {
    const newFilters = this.filters.map((filter) => {
      if (filter.type === filterType) {
        return {
          ...filter,
          value: filter.value?.filter((v) => !filterValues.includes(v))
        };
      }
      return filter;
    });
    this.replaceFilters(newFilters);
  }

  @action.bound
  setSelectedTile(tile: TileType): void {
    this.selectedTile = tile;
  }

  @action.bound
  async removeDevices(devices: DeviceDocument[]): Promise<void> {
    const removePromises = devices.map((device) =>
      this.iotClient.deleteDevice(device.id, this.tenantId as string)
    );
    await Promise.all(removePromises);
    await this.fetchDevicesStatus();
  }

  @action.bound
  async createDevicesHandler(deviceIds: string): Promise<void> {
    await this.addNewDevices(deviceIds);
    await this.fetchDevicesStatus();
  }

  @action.bound
  async addNewDevices(deviceIds: string): Promise<void> {
    this.addNewDevicesFinished = false;
    const deviceIdsList = deviceIds.split(',');
    for (let i = 0; i < deviceIdsList.length; i++) {
      await this.addNewDevice(deviceIdsList[i].trim());
    }
    this.addNewDevicesFinished = true;
  }

  @action.bound
  async refresh(): Promise<void> {
    await this.fetchDevices(false);
  }

  @action
  private async addNewDevice(deviceId: string): Promise<void> {
    if (this.tenantId) {
      try {
        const { success, device } = await this.iotClient.createDevice(this.tenantId, deviceId);
        if (success && device) {
          this.devices?.push(device);
          this.createdDeviceIds.push(device.id);
          this.totalDevices += 1;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  @action.bound
  public async updateDeviceTypeId(deviceId: string, deviceTypeId: string) {
    if (this.tenantId) {
      try {
        const params = {
          meta: {
            deviceTypeId
          }
        };

        const { success, device } = await this.iotClient.updateDevice(
          this.tenantId,
          deviceId,
          params
        );

        if (success && device) {
          this.devices =
            this.devices?.map((d) => {
              if (device.id === d.id) {
                return device;
              }
              return d;
            }) || null;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  @action.bound
  public clearCreatedAndInvalidDeviceIds(): void {
    this.createdDeviceIds = [];
    this.invalidDeviceIds = [];
    this.addNewDevicesFinished = false;
  }

  get devicesToRender(): DeviceDocument[] | undefined {
    return this.devices?.map((device) => new DeviceDocument(device));
  }

  @computed
  get activeDevices(): DeviceDocument[] | undefined {
    return this.devices
      ?.filter((device) => {
        return !device.meta?.inactivated;
      })
      .map((device) => new DeviceDocument(device));
  }

  @computed
  get totalNumberOfPages(): number {
    return Math.ceil(this.totalDevices / this.pageSize);
  }

  @computed
  get hasPrev(): boolean {
    return this.page > INITIAL_PAGE;
  }

  @computed
  get hasMore(): boolean {
    return this.page < this.totalNumberOfPages - 1;
  }

  @computed
  get uniqueDeviceTypes(): IDeviceType[] {
    return this.availableDeviceTypeIds;
  }

  @computed
  get uniqueFirmwareVersions(): string[] {
    return this.availableFirmwareVersions;
  }

  @computed
  get activeFilters(): {
    deviceIds: string[];
    types: string[];
    configurations: string[];
    firmwareVersions: string[];
  } {
    const deviceIds =
      (this.filters.find((filter: Filter) => filter.type === FilterTypes.DeviceIds)
        ?.value as string[]) || [];
    const types =
      (this.filters.find((filter: Filter) => filter.type === FilterTypes.DeviceTypeIds)
        ?.value as string[]) || [];
    const configurations =
      (this.filters.find((filter: Filter) => filter.type === FilterTypes.ConfigurationRefIds)
        ?.value as string[]) || [];
    const firmwareVersions =
      (this.filters.find((filter: Filter) => filter.type === FilterTypes.FirmwareVersions)
        ?.value as string[]) || [];
    return {
      configurations,
      deviceIds,
      firmwareVersions,
      types
    };
  }

  @computed
  get isTotalTile(): boolean {
    const activeNonHealthStatusFilter = this.filters.find(
      (filter) =>
        filter.type === FilterTypes.ConfigurationRefIds ||
        filter.type === FilterTypes.DeviceTypeIds ||
        filter.type === FilterTypes.DeviceIds
    );
    return activeNonHealthStatusFilter !== undefined && this.selectedTile !== TileType.Total;
  }

  // normal methods
  getNumberOfDevicesWithConfig(configurationName?: string): number {
    const deviceWithConfig =
      this.activeDevices?.filter((device) => {
        if (device.deviceConfigurationId && configurationName) {
          return configurationName === device.deviceConfigurationId;
        }
        return undefined;
      }) || [];
    return deviceWithConfig.length;
  }

  getPaginationSize(): number[] {
    return SIZES;
  }

  getDevice(deviceId: string): DeviceDocument | undefined {
    const device = this.devices?.find(({ id }) => id === deviceId);
    if (device) return new DeviceDocument(device);
    return undefined;
  }

  getDevices(deviceIds: string[]): DeviceDocument[] | undefined {
    return this.devices
      ?.filter(({ id }) => id && deviceIds.includes(id))
      .map((device) => new DeviceDocument(device));
  }
}
