import { Modal, ModalButton } from 'baseui/modal';
import { StyledSpinnerNext } from 'baseui/spinner';
import { addMinutes, subMinutes } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { DEVICE_LOGS_MODAL, DEVICE_LOGS_MODAL_COPY_BUTTON } from '../../e2e/testIds';
import { useStores } from '../../hooks/use-stores';
import { themedUseStyletron } from '../../theme/theme';
import {
  StyledModalBody,
  StyledModalHeader,
  ModalFooter,
  CenterContainer,
} from '../modal/ModalHelper';
import { Pagination } from '../pagination/Pagination';

import { DeviceLogModalContent } from './DeviceLogModalContent';
import { DeviceLogModalInput } from './DeviceLogModalInput';

export interface DeviceLogProps {
  deviceId: string;
  isOpen: boolean;
  onClose: () => void;
}

const NOW = new Date();
const TIMEZONE_OFFSET = NOW.getTimezoneOffset();
const END = addMinutes(NOW, TIMEZONE_OFFSET); // convert current local time to UTC
const START = subMinutes(END, 30); // get the last 30 minutes
const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 50;

export const DeviceLogModalError = observer(({ deviceId, isOpen, onClose }: DeviceLogProps) => {
  const [css] = themedUseStyletron();
  const { deviceStore } = useStores();
  const [dates, setDates] = useState([START, END]);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const deviceDocument = deviceStore.getDevice(deviceId);
  const eventLogs = deviceDocument?.events || [];
  const [startDate, endDate] = dates;

  useEffect(() => {
    if (deviceDocument && startDate && endDate) {
      deviceDocument.fetchEvents(startDate, endDate, page, pageSize);
    }
  }, [deviceDocument, startDate, endDate, page, pageSize]);

  const onPageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPage(INITIAL_PAGE);
  };

  const tableData: string[][] = eventLogs.map((event) => [
    event.timestamp.toISOString(),
    event.eventType,
    event.code,
    event.operation,
  ]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      unstable_ModalBackdropScroll
      autoFocus={false}
      overrides={{
        Close: {
          style: {
            display: 'none',
          },
        },
        Dialog: {
          props: {
            'data-test-id': DEVICE_LOGS_MODAL,
          },
          style: {
            width: 'auto',
          },
        },
      }}
    >
      <StyledModalHeader>{`Device Errors – ${deviceId}`}</StyledModalHeader>
      <StyledModalBody>
        <CenterContainer>
          <DeviceLogModalInput
            value={dates}
            onChange={(nextDates: Date[]) => {
              setDates(nextDates);
              setPage(INITIAL_PAGE);
            }}
          />
          {!deviceDocument || !deviceDocument.eventsReady ? (
            <StyledSpinnerNext />
          ) : (
            <>
              <DeviceLogModalContent
                data={tableData}
                columns={['Timestamp', 'EventType', 'Code', 'Operation']}
              />
              <div className={css({ alignSelf: 'flex-end', marginTop: '16px' })}>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  onPrev={() => setPage(page - 1)}
                  onNext={() => setPage(page + 1)}
                  hasPrev={page > 0}
                  hasNext={eventLogs.length === pageSize}
                  perPageLabel="Errors per page"
                  recordsLabel="errors"
                />
              </div>
            </>
          )}
        </CenterContainer>
      </StyledModalBody>
      <ModalFooter>
        {deviceDocument?.events && deviceDocument?.events.length > 0 && (
          <ModalButton
            kind="secondary"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(deviceDocument?.events, null, 2));
            }}
            overrides={{
              BaseButton: {
                props: {
                  'data-test-id': DEVICE_LOGS_MODAL_COPY_BUTTON,
                },
              },
            }}
          >
            Copy to Clipboard
          </ModalButton>
        )}

        <ModalButton kind="primary" onClick={onClose}>
          Close
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
});
