// Taken from Wunder's Design System
// https://www.figma.com/file/0TJTAPtUkKsA0yo9q3Y3XE6x/Web-Components-%F0%9F%96%A5?node-id=1062%3A48
// Use it for Box-Shadows
export const themeShadows = {
  elevation_1: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  elevation_2: '0px 2px 8px rgba(0, 0, 0, 0.12)',
  elevation_3: '0px 8px 16px rgba(0, 0, 0, 0.12)',
  elevation_4: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  elevation_5: '0px 24px 32px rgba(0, 0, 0, 0.12)',
  elevation_6: '0px -12px 16px rgba(0, 0, 0, 0.12)',
};
