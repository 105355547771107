// Taken from Wunder's Design System
// https://www.figma.com/file/3uMuKxOkJrRyPwfu7L1Y1n/Global-Components-Web-%F0%9F%96%A5?node-id=1979%3A0
// Use for: margins, paddings, grid-gaps, and positioning in general
export const themeSizes = {
  scale0: '0',
  scale100: '4px',
  scale200: '8px',
  scale300: '12px',
  scale400: '16px',
  scale500: '24px',
  scale550: '32px',
  scale600: '40px',
  scale650: '48px',
  scale700: '64px',
  scale750: '80px',
  scale1050: '176px',
};
