import { Radio as BaseWebRadio, RadioProps as BaseWebRadioProps, RadioGroup } from 'baseui/radio';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';

export { RadioGroup };

export const Radio = ({ children, ...others }: BaseWebRadioProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <BaseWebRadio
      {...others}
      overrides={{
        RadioMarkInner: {
          style: ({ $checked }): StyleObject => ({
            width: $checked ? theme.sizes.scale200 : '1.25rem', // edge case (with subtracted border)
            height: $checked ? theme.sizes.scale200 : '1.25rem', // edge case (with subtracted border)
          }),
        },
        RadioMarkOuter: {
          style: ({ $checked }): StyleObject => ({
            width: theme.sizes.scale500,
            height: theme.sizes.scale500,
            backgroundColor: $checked ? theme.colors.primary100 : theme.colors.bg25,
          }),
        },
      }}
    >
      {children}
    </BaseWebRadio>
  );
};
