import { DeviceCommandInstruction } from '../types';

const SUPPORTED_ACTIONS_OMNI = [
  DeviceCommandInstruction.alarm,
  DeviceCommandInstruction.honk,
  DeviceCommandInstruction.volumeOn,
  DeviceCommandInstruction.volumeOff,
  DeviceCommandInstruction.lockAndImmobilise,
  DeviceCommandInstruction.unlockAndMobilise,
  DeviceCommandInstruction.currentPosition,
  DeviceCommandInstruction.info,
  DeviceCommandInstruction.firmware,
  DeviceCommandInstruction.lightsOn,
  DeviceCommandInstruction.lightsOff,
  DeviceCommandInstruction.throttleOn,
  DeviceCommandInstruction.throttleOff,
  DeviceCommandInstruction.rebootIot,
  DeviceCommandInstruction.turnOffIot,
  DeviceCommandInstruction.setMaxSpeed,
  DeviceCommandInstruction.firmwareUpdate
];

export const YIMIOM1000 = {
  id: 'YIMIOM1000',
  name: 'YIMI 5',
  supportedActions: SUPPORTED_ACTIONS_OMNI
};
export const YIMIOM1001 = {
  id: 'YIMIOM1001',
  name: 'YIMI Plus 3',
  supportedActions: [...SUPPORTED_ACTIONS_OMNI, DeviceCommandInstruction.batteryUnlock]
};

export const ACTOOM0000 = {
  id: 'ACTOOM0000',
  name: 'Acton M Pro',
  supportedActions: SUPPORTED_ACTIONS_OMNI
};

export const ACTOOM0001 = {
  id: 'ACTOOM0001',
  name: 'Acton M Pro - Cable Lock',
  supportedActions: [...SUPPORTED_ACTIONS_OMNI, DeviceCommandInstruction.cableUnlock]
};

export const ACTOOM0002 = {
  id: 'ACTOOM0002',
  name: 'Acton M Pro 2021 - Cable Lock',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OMNI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.batteryLock,
    DeviceCommandInstruction.cableUnlock
  ]
};

export const FIIKOM1000 = {
  id: 'FIIKOM1000',
  name: 'Ride FIIK S8',
  supportedActions: SUPPORTED_ACTIONS_OMNI
};

export const FREEOM1001 = {
  id: 'FREEOM1001',
  name: 'Freego ES-08S V4.1',
  supportedActions: [...SUPPORTED_ACTIONS_OMNI, DeviceCommandInstruction.batteryUnlock]
};

export const FREEOM1002 = {
  id: 'FREEOM1002',
  name: 'Freego ES-08S V4.6',
  supportedActions: [...SUPPORTED_ACTIONS_OMNI, DeviceCommandInstruction.batteryUnlock]
};

export const OMNIOM0000 = {
  id: 'OMNIOM0000',
  name: 'OGB1',
  supportedActions: [
    DeviceCommandInstruction.honk,
    DeviceCommandInstruction.unlockAndMobilise,
    DeviceCommandInstruction.currentPosition
  ]
};

export const YADEOM0001 = {
  id: 'YADEOM0001',
  name: 'Yadea E-Bike',
  supportedActions: [
    ...SUPPORTED_ACTIONS_OMNI,
    DeviceCommandInstruction.batteryUnlock,
    DeviceCommandInstruction.cableUnlock
  ]
};

export const DYNAOM0001 = {
  id: 'DYNAOM0001',
  name: 'Dyna E-Bike',
  supportedActions: [...SUPPORTED_ACTIONS_OMNI, DeviceCommandInstruction.batteryUnlock]
};

export const OMNI_DEVICES = [
  YIMIOM1000,
  YIMIOM1001,
  ACTOOM0000,
  ACTOOM0001,
  ACTOOM0002,
  FIIKOM1000,
  FREEOM1001,
  FREEOM1002,
  OMNIOM0000,
  YADEOM0001,
  DYNAOM0001
];
