import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { Formik, FormikProps, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import React, { MouseEvent, ReactNode, RefObject } from 'react';
import { StyleObject } from 'styletron-react';

import { themedWithStyle } from '../../theme/theme';

import { FormField } from './formFieldTypes';

export type CloseHandler = () => void;

export type SubmitHandler<V> = (values: V) => Promise<void> | void;

const StyledModalHeader = themedWithStyle(
  ModalHeader,
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingLargeSemiBold,
    color: $theme.colors.text100
  })
);

const StyledModalBody = themedWithStyle(
  ModalBody,
  ({ $theme }): StyleObject => ({
    marginBottom: $theme.sizes.scale0
  })
);

const StyledModalFooter = themedWithStyle(
  ModalFooter,
  ({ $theme }): StyleObject => ({
    borderTopWidth: $theme.sizes.scale0,
    marginTop: `-${$theme.sizes.scale300}`,
    paddingBottom: $theme.sizes.scale500
  })
);

export interface DialogFormChildrenProps<V> extends FormikProps<V> {
  loading: boolean;
}

interface DialogFormBaseProps<V> {
  children?: (props: DialogFormChildrenProps<V>) => ReactNode;
  initialValues: V;
  loading: boolean;
  onClose: CloseHandler;
  onSubmit: SubmitHandler<V>;
  open: boolean;
  title: string;
  readonly?: boolean;
  size?: 'default' | 'large';
}

export function DialogFormBase<V extends FormikValues>({
  children,
  initialValues,
  loading,
  onClose,
  onSubmit,
  open,
  title,
  readonly,
  size = 'default'
}: DialogFormBaseProps<V>): JSX.Element {
  const formRef: RefObject<HTMLFormElement> = React.createRef();

  function onCancel(event: MouseEvent) {
    event.preventDefault();
    onClose();
  }

  return (
    <Modal
      onClose={onClose}
      size='default'
      isOpen={open}
      unstable_ModalBackdropScroll
      overrides={{
        Close: {
          style: {
            display: 'none'
          }
        },
        Dialog: {
          style: {
            width: size === 'large' ? '960px' : '500px'
          }
        }
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: V, actions): Promise<void> => {
          await onSubmit(values);
          actions.setSubmitting(false);
        }}
        validateOnMount
      >
        {(props): JSX.Element => {
          const { handleSubmit, isSubmitting, errors } = props;
          const disabledSaveButton = loading || isSubmitting || !isEmpty(errors);

          return (
            <form onSubmit={handleSubmit} ref={formRef}>
              <StyledModalHeader>{title}</StyledModalHeader>
              <StyledModalBody>{children?.({ ...props, loading })}</StyledModalBody>
              <StyledModalFooter>
                <ModalButton kind='secondary' disabled={loading || isSubmitting} onClick={onCancel}>
                  Cancel
                </ModalButton>
                <ModalButton
                  type='submit'
                  isLoading={isSubmitting}
                  disabled={disabledSaveButton || readonly}
                >
                  Save
                </ModalButton>
              </StyledModalFooter>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export function generateFieldNames(fields: FormField[], parentName?: string): FormField[] {
  return fields.map((field: FormField): FormField => {
    const newField = { ...field };

    if (parentName) {
      newField.name = `${parentName}.${newField.name}`;
    }

    if (newField.type === 'group') {
      newField.fields = generateFieldNames(newField.fields, newField.name);
    }

    return newField;
  });
}
