import { Field, FieldProps, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleObject, StyletronComponent } from 'styletron-react';

import { DeviceConfigurationParams } from '../../models';
import { themedStyled, themedUseStyletron as useStyletron } from '../../theme/theme';
import { getSupportedFieldNames } from '../../utils/configurationMapping';
import { Button } from '../button/Button';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import { FormField, FormFields, TabbedFormField } from '../formHelper/formFieldTypes';
import { renderFields } from '../formHelper/renderFields';
import { Tab, Tabs } from '../tabs/Tabs';

import { Footer, ViewState } from './DeviceConfigurationHelper';
import { DeviceConfigurationSettingsHeadline } from './DeviceConfigurationSettingsHeadline';
import { DeviceConfigurationType } from './DeviceConfigurationType';
import { getDeviceConfigurationFields } from './deviceConfigurationFormFields';

interface DeviceConfigurationSettingsProps {
  configuration: DeviceConfigurationParams;
  updateDeviceConfiguration: (currentConfiguration: DeviceConfigurationParams) => Promise<void>;
  updateCurrentView: (view: ViewState) => void;
  currentView: ViewState;
  editable?: boolean;
  numberOfDevicesWithSelectedConfig: number;
}

const Content = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    background: $theme.colors.bgWhite,
    flexGrow: 1,
    paddingBottom: $theme.sizes.scale500,
    paddingLeft: $theme.sizes.scale400,
    paddingRight: $theme.sizes.scale400
  })
);

export const FormContainer: StyletronComponent<any> = themedStyled(
  'form',
  (): StyleObject => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  })
);

function validateDeviceType(value: string): string | undefined {
  return !value ? 'VehicleType is required' : undefined;
}

export const generateFieldNames = (formFields: FormField[], parentName?: string): FormField[] => {
  return formFields.map((field: FormField): FormField => {
    const newField = { ...field };

    if (parentName) {
      newField.name = `${parentName}.${newField.name}`;
    }

    if (newField.type === 'group') {
      newField.fields = generateFieldNames(newField.fields, newField.name);
    }

    return newField;
  });
};

export const DeviceConfigurationSettings = ({
  configuration,
  updateDeviceConfiguration,
  updateCurrentView,
  currentView,
  editable = false,
  numberOfDevicesWithSelectedConfig
}: DeviceConfigurationSettingsProps): JSX.Element | null => {
  const fields = getDeviceConfigurationFields(configuration.deviceTypeId) as FormFields;
  const { tabLabel } = fields[0] as TabbedFormField;
  const [configurationName, setConfigurationName] = React.useState<string>(configuration.name);
  const [activeKey, setActiveKey] = React.useState<string | number>(tabLabel);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
  const [formikValues, setFormikValues] = React.useState<DeviceConfigurationParams>();
  const [, theme] = useStyletron();
  const { t } = useTranslation();

  const tabbedField = (fields as TabbedFormField[]).filter((field) => field.tabLabel === activeKey);

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>): void => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const BodyText = themedStyled(
    'p',
    ({ $theme }): StyleObject => ({
      ...$theme.typography.contentBodyRegular,
      marginBottom: $theme.sizes.scale400,
      marginTop: $theme.sizes.scale0
    })
  );

  const BoldBodyText = themedStyled(
    'strong',
    ({ $theme }): StyleObject => ({
      ...$theme.typography.contentBodyBold
    })
  );

  const ConfirmationModalContent = () => {
    return (
      <BodyText>
        Are you sure you want to update the settings of{' '}
        <BoldBodyText>{numberOfDevicesWithSelectedConfig}</BoldBodyText> devices? <br />
        Be aware that incorrect settings result in malfunctions, connection loss of your fleet, or
        exceed your request limits. <br />
        Please type <BoldBodyText>{configurationName}</BoldBodyText> to confirm.
      </BodyText>
    );
  };

  return (
    <Formik
      initialValues={configuration}
      onSubmit={(values: DeviceConfigurationParams, { setSubmitting }) => {
        setSubmitting(false);
        setFormikValues(values);
        setIsConfirmationModalOpen(true);
      }}
    >
      {(props): JSX.Element => {
        const { handleSubmit, values } = props;

        let currentFields = tabLabel ? tabbedField[0].formFields : (fields as FormField[]);

        if (values.deviceTypeId) {
          const supportedFieldNames = getSupportedFieldNames(values.deviceTypeId);
          currentFields = currentFields.filter((formField) =>
            supportedFieldNames.includes(formField.name)
          );
        }

        return (
          <FormContainer onKeyDown={onKeyDown} onSubmit={handleSubmit}>
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              validateInput={(inputText) => inputText === configurationName}
              title='Really update Configuration?'
              placeholder='Name of configuration'
              primaryButtonText='Update Configuration'
              primaryAction={async (): Promise<void> => {
                updateCurrentView(ViewState.list);
                setIsConfirmationModalOpen(!isConfirmationModalOpen);

                if (formikValues) {
                  await updateDeviceConfiguration({
                    ...formikValues,
                    name: configurationName
                  });
                  setFormikValues(undefined);
                }
              }}
              secondaryButtonText='Abort'
              onClose={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
            >
              <ConfirmationModalContent />
            </ConfirmationModal>

            <DeviceConfigurationSettingsHeadline
              nameInput={configurationName}
              setNameInput={setConfigurationName}
              updateCurrentView={updateCurrentView}
            />
            <Field name='deviceTypeId' validate={validateDeviceType}>
              {({ field, form, meta }: FieldProps<string>) => (
                <DeviceConfigurationType
                  label={t('deviceConfiguration.type.name')}
                  tooltip={t('deviceConfiguration.type.tooltip')}
                  value={field.value}
                  disabled={Boolean(configuration.deviceTypeId)}
                  error={meta.error}
                  onChange={(value: unknown) => {
                    const { name } = field;
                    form.setFieldValue(name, value);
                    form.setFieldTouched(name, true, false);
                    form.validateField(name);
                  }}
                />
              )}
            </Field>

            {values.deviceTypeId && (
              <Content>
                <Tabs
                  activeKey={activeKey}
                  onChange={({ activeKey: active }): void => {
                    setActiveKey(active);
                  }}
                  style={{
                    backgroundColor: theme.colors.bg10,
                    marginLeft: `-${theme.sizes.scale400}`,
                    marginRight: `-${theme.sizes.scale400}`,
                    paddingLeft: theme.sizes.scale400,
                    paddingRight: theme.sizes.scale400
                  }}
                >
                  {(fields as TabbedFormField[]).map((field: TabbedFormField) => (
                    <Tab key={field.tabLabel} title={field.tabLabel}>
                      {renderFields(generateFieldNames(currentFields), {
                        ...props,
                        loading: false
                      })}
                    </Tab>
                  ))}
                </Tabs>
              </Content>
            )}

            <Footer>
              <Button variant='primary' size='large' type='submit' disabled={!editable}>
                {currentView === ViewState.new ? `Save Configuration` : `Update Configuration`}
              </Button>
            </Footer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
