import path from 'path';

import { User } from '../../models/userDocument';
import { TokenHandler } from '../../utils/tokenHandler';
import { AuthClient, AuthResponse } from '../interfaces';

export class AuthApiClient implements AuthClient {
  constructor(readonly baseURL: string) {}

  public async authenticate(email: string, password: string): Promise<AuthResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, '/login');
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      const result: { token: string } = await response.json();
      TokenHandler.setToken(result.token);
      return { ...result, success: true };
    } catch (err) {
      return {
        error: 'Error occurred when authenticating to iot-api',
        success: false,
      };
    }
  }

  public async refresh(userIdToken: string): Promise<AuthResponse> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, '/refresh');
    try {
      const response = await fetch(url.href, {
        body: JSON.stringify({
          userIdToken,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      const result: { token: string } = await response.json();
      TokenHandler.setToken(result.token);
      return { ...result, success: true };
    } catch (err) {
      return {
        error: 'Error occurred when refreshing token',
        success: false,
      };
    }
  }

  public async getCurrentUser(): Promise<User | null> {
    const url = new URL(this.baseURL);
    url.pathname = path.join(url.pathname, '/me');
    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenHandler.getToken()}`,
        },
        method: 'GET',
      });
      const user = await response.json();
      return user as User;
    } catch (err) {
      return null;
    }
  }
}
